import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { PasswordService } from '../password.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-create-district-admin',
  templateUrl: './create-district-admin.component.html',
  styleUrls: ['./create-district-admin.component.css'],
})
export class CreateDistrictAdminComponent implements OnInit {
  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService
  ) {}

  ngOnInit() {
    this.getDistrictAdmin();
  }

  //variables
  district: string = '';
  eMailID: string = '';
  keyword: string = '';
  spinner: boolean = false;
  districtAdmin: any;
  districtLabel: string = '';
  districtValue: string = '';
  emailError: string = '';

  spinnerForCreateDistrictAdminButton: boolean = false;

  districts = [
    { value: 'Alappuzha', label: 'Alappuzha' },
    { value: 'Ernakulam', label: 'Ernakulam' },
    { value: 'Idukki', label: 'Idukki' },
    { value: 'Kannur', label: 'Kannur' },
    { value: 'Kasaragod ', label: 'Kasaragod' },
    { value: 'Kollam', label: 'Kollam' },
    { value: 'Kottayam', label: 'Kottayam' },
    { value: 'Kozhikode', label: 'Kozhikode' },
    { value: 'Malappuram', label: 'Malappuram' },
    { value: 'Palakkad', label: 'Palakkad' },
    { value: 'Pathanamthitta', label: 'Pathanamthitta' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Wayanad', label: 'Wayanad' },
  ];

  validateEmail(control: AbstractControl): ValidationErrors | null {
    const email = control.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      return { required: 'Email is required' };
    }

    if (!emailRegex.test(email)) {
      return { invalidFormat: 'Invalid email format' };
    }

    return null;
  }

  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;
  }

  createdMailID: string = '';
  createdPassword: string = '';
  //create the district admin
  createDistrictAdmin() {
    this.district = this.districtLabel;
    this.emailError = '';

    if (!this.district || !this.eMailID || !this.keyword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter all the fields',
      });
      return;
    }

    const emailValidation = this.validateEmail({
      value: this.eMailID,
    } as AbstractControl);
    if (emailValidation) {
      this.emailError =
        emailValidation['required'] || emailValidation['invalidFormat'];
      return;
    }
    Swal.fire({
      icon: 'info',
      title: 'Create district admin',
      text: 'Do you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerForCreateDistrictAdminButton = true;
        const timestamp = Date.now();
        const formattedTimestamp = new Date(timestamp)
          .toISOString()
          .replace(/[^\d]/g, ''); // Remove non-digit characters
        const profileID = 'USER_ID' + formattedTimestamp;
        this.spinner = true;
        const token = this.Authservice.getToken();
        console.log('the order function is called');
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        console.log('the headers are', headers);

        const params = new HttpParams()
          .set('district', this.district)
          .set('eMailID', this.eMailID)
          .set('keyword', this.keyword)
          .set('profileID', profileID);

        this.http
          .post(QS_ADMIN_URL + '/createDistrictAdmin', {}, { headers, params })
          .subscribe(
            (data) => {
              console.log('account created');

              this.getDistrictAdmin();
              this.spinner = false;
              this.createdMailID = this.eMailID;
              this.createdPassword = this.keyword;

              this.openEmail();
              this.clearTextBox();
              this.spinnerForCreateDistrictAdminButton = false;
              // Handle success response if needed
            },
            (error) => {
              console.error('Error', error);
              this.spinner = false;
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else if (error.status === 400) {
                alert('Error in creating district admin');
                this.spinnerForCreateDistrictAdminButton = false;
                window.location.reload();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
                this.spinnerForCreateDistrictAdminButton = false;
              }
              // Handle error response
            }
          );
      } else {
        // Handle if the user clicks 'No'
        console.log('User chose not to continue');
      }
    });
  }

  closeTheCard(){
    this.createdMailID = '';
    this.createdPassword = '';
  }

  openEmail() {
    const recipient = this.eMailID;

    const subject = 'Password';
    const body = `
    Hi,
    
    You have successfully registered in PocketMart Quick serve.

    Your username : ${recipient}
    Your temporary keyword is : ${this.keyword}.

    Please login to change your password.

    Thanks and regards.
    PocketMart Team.
    
    `;

    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${encodeURIComponent(
      body
    )}`;
    window.location.href = mailtoLink;
  }

  currentPage: number = 1;
  totalPages: number = 1;
  limit: number = 25;
  //to get the details
  getDistrictAdmin(page: number = 1) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + '/districtAdminsDetails', { headers, params: { page, limit: this.limit } })
      .subscribe(
        (response: any) => {
          this.districtAdmin = response.districtAdmins;
          this.totalPages = response.totalPages;
          this.currentPage = response.currentPage;
          console.log('District Admin', this.districtAdmin);
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
          }
        }
      );
  }

  onPageChange(page: number) {
    this.getDistrictAdmin(page);
  }

  passwordReset(userName: string) {
    const newPassword = this.passwordService.generateRandomPassword();
    const passwordReset = newPassword;
    const username = userName;

    Swal.fire({
      title: 'Are you sure you want to reset the password?',
      text: 'The new password is: ' + passwordReset,
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('newKeyword', passwordReset)
          .set('username', username);

        const options = { headers, params };
        this.http.put(QS_ADMIN_URL + '/passwordRest', {}, options).subscribe(
          (data: any) => {
            Swal.fire(data.message);
          },
          (error) => {
            console.log(error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
            }
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Password reset cancelled', '', 'info');
      }
    });
  }

  accountDelete(userName: string) {
    const accountDelete = true;
    const username = userName;

    Swal.fire({
      title: 'Are you sure you want to delete the account?',
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('accountDelete', accountDelete)
          .set('username', username);

        const options = { headers, params };
        this.http.put(QS_ADMIN_URL + '/deleteAccount', {}, options).subscribe(
          (data: any) => {
            Swal.fire(data.message);
            this.getDistrictAdmin();
          },
          (error) => {
            console.log(error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
            }
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('delete account cancelled', '', 'info');
      }
    });
  }

  clearTextBox() {
    this.district = '';
    this.eMailID = '';
    this.keyword = '';
    this.districtLabel = '';
    this.districtValue = '';

    const districtSelect = document.getElementById(
      'districtSelect'
    ) as HTMLSelectElement;
    if (districtSelect) {
      districtSelect.selectedIndex = 0;
    }
  }

  createPassword() {
    // const newPassword = this.generateRandomPassword();
    const newPassword = this.passwordService.generateRandomPassword();
    this.keyword = newPassword;
  }
}
