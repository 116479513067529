import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/auth-service.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
})
export class SideBarComponent {
goToBookServiceManually: any;
  constructor(
    private router: Router,
    private Authservice: AuthServiceService
  ) {}

  checkLogin(): void {
    const isLoggedIn = this.Authservice.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }

  goToAnalyticsPage() {
    this.router.navigate(['/analyticsPage']);
  }

  goToCreateMeUnit() {
    this.router.navigate(['/createMeUnit']);
  }

  goToCreateULB() {
    this.router.navigate(['/createULB']);
  }

  goToMeUnitsListings() {
    this.router.navigate(['/meUnitListing']);
  }

  goToAddServiceProvider() {
    this.router.navigate(['/addServiceProvider']);
  }

  goToServiceRequestListing() {
    this.router.navigate(['/serviceRequestListings']);
  }

  goToServiceProviderListing() {
    this.router.navigate(['/serviceProviderListings']);
  }

  goToCreateDistrictAdmin() {
    this.router.navigate(['/createDistrictAdmin']);
  }
  
  goToBookServicesManually() {
    this.router.navigate(['/bookServiceManually']);
  }

  goToULBListings() {
    this.router.navigate(['/ulbListings']);
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isULB(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isULB();
  }
  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }
}
