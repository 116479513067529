import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { PasswordService } from '../password.service';
import Swal from 'sweetalert2';
import { Route, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-create-me-unit',
  templateUrl: './create-me-unit.component.html',
  styleUrls: ['./create-me-unit.component.css']
})
export class CreateMeUnitComponent implements OnInit {
  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getULBLogin()
  }

  keyword: string = '';
  district:string = '';
  phoneNumber: number = null;
  spinner: boolean = false;
  ULBDetails:any;
  ULBName: string = '';

  createPassword() {
    const newPassword = this.passwordService.generateRandomPassword();
    this.keyword = newPassword;
  }

  validateAge(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  checkLength(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber = parseInt(input.value, 10);
    }
  }

   //create the district admin
   createMEUnitAdmin() {
    if ( !this.phoneNumber || !this.keyword || !this.district) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter all the fields',
      });
      return;
    }

    if ( this.phoneNumber.toString().length !== 10) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Phone number must be 10 characters',
      });
      return;
    }


    Swal.fire({
      icon: 'info',
      title: 'Create ME Unit',
      text: 'Do you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        const timestamp = Date.now();
        const formattedTimestamp = new Date(timestamp).toISOString().replace(/[^\d]/g, ''); // Remove non-digit characters
        const profileID = 'USER_ID' + formattedTimestamp;
        this.spinner = true;
        const token = this.Authservice.getToken();
        console.log('the order function is called');
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        console.log('the headers are', headers);

        const params = new HttpParams()
          .set('district', this.district)
          .set('phoneNumber', this.phoneNumber)
          .set('keyword', this.keyword)
          .set('ULBName', this.ULBName)
          .set('profileID', profileID);

        this.http
          .post(QS_ADMIN_URL + '/createMEUnit', {}, { headers, params })
          .subscribe(
            (data) => {
              console.log('account created');
              this.clearTextBox();
              this.spinner = false;
              this.router.navigate(['/meUnitListing']);
              // Handle success response if needed
            },
            (error) => {
              console.error('Error', error);
              this.spinner = false;
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
              // Handle error response
            }
          );
      } else {
        // Handle if the user clicks 'No'
        console.log('User chose not to continue');
      }
    });
  }

  clearTextBox() {
   
    this.phoneNumber = null;
    this.keyword = '';
  }

  getULBLogin() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + '/ULBLogin', { headers }) // Changed from post to get
      .subscribe(
        (data) => {
          this.ULBDetails = data;
          console.log('ULBList', this.ULBDetails);
          this.district = this.ULBDetails.district;
          this.ULBName = this.ULBDetails.name;

          console.log('district', this.district);
          console.log('ULBName', this.ULBName)

          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
          }
          // Handle error response
        }
      );
  }


  
}
