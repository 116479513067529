import { Component, OnInit} from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/auth-service.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private Authservice: AuthServiceService
  ) {}

  user: string = null;

  ngOnInit() {
    this.checkUser();
  }

  checkUser(){
    if(this.isStateAdmin()){
      this.user = 'State Admin';
    }
    else if(this.isSuperAdmin()){
      this.user = 'Super Admin';
    }
    else if(this.isDistrictAdmin()){
      this.user = 'District Admin';
    }
    else if(this.isULB()){
      this.user = 'CMMU User';
    }
    else if(this.isMEUnit()){
      this.user = 'ME Unit';
    }
  }


  toggleFullScreen() {
    if (!document.fullscreenElement) {
      // Enter full-screen mode
      document.documentElement.requestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  onLogout(){
    this.Authservice.logOut();
   
  }

  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }

  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isULB(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isULB();
  }


  

}
