import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginPageComponent } from'./components/login-page/login-page.component'
import { SideBarComponent } from './components/side-bar/side-bar.component'
import { AnalyticsPageComponent } from './components/analytics-page/analytics-page.component'
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CreateMeUnitComponent } from './components/create-me-unit/create-me-unit.component';
import { CreateULBComponent } from './components/create-ulb/create-ulb.component';
import { MeUnitListingsComponent } from './components/me-unit-listings/me-unit-listings.component';
import { AddServiceProviderComponent } from './components/add-service-provider/add-service-provider.component';
import { ServiceRequestListingsComponent } from './components/service-request-listings/service-request-listings.component';
import { ServiceProviderListingComponent } from './components/service-provider-listing/service-provider-listing.component';
import { ServiceProviderDescriptionComponent } from './components/service-provider-description/service-provider-description.component';
import { MeUnitDescriptionComponent } from './components/me-unit-description/me-unit-description.component';
import { CreateDistrictAdminComponent } from './components/create-district-admin/create-district-admin.component';
import { UlbListingsComponent } from './components/ulb-listings/ulb-listings.component';
import { ServiceRequestDescriptionComponent } from './components/service-request-description/service-request-description.component'
import { BookServiceManuallyComponent } from './components/book-service-manually/book-service-manually.component';
const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'sideBar', component: SideBarComponent },
  { path: 'analyticsPage', component: AnalyticsPageComponent,canActivate: [AuthGuard], },
  { path: 'profilePage', component: ProfilePageComponent,canActivate: [AuthGuard], },
  { path: 'changePassword', component: ChangePasswordComponent,canActivate: [AuthGuard], },
  { path: 'createMeUnit', component: CreateMeUnitComponent,canActivate: [AuthGuard], },
  { path: 'createULB', component: CreateULBComponent,canActivate: [AuthGuard], },
  { path: 'meUnitListing', component: MeUnitListingsComponent,canActivate: [AuthGuard], },
  { path: 'addServiceProvider', component: AddServiceProviderComponent, canActivate: [AuthGuard], },
  { path: 'serviceRequestListings', component: ServiceRequestListingsComponent,canActivate: [AuthGuard], },
  { path: 'serviceProviderListings', component: ServiceProviderListingComponent,canActivate: [AuthGuard], },
  { path: 'serviceProviderDescription', component: ServiceProviderDescriptionComponent,canActivate: [AuthGuard], },
  { path: 'meUnitDescription', component: MeUnitDescriptionComponent,canActivate: [AuthGuard], },
  { path: 'createDistrictAdmin', component: CreateDistrictAdminComponent,canActivate: [AuthGuard], },
  { path: 'ulbListings', component: UlbListingsComponent,canActivate: [AuthGuard], },
  { path: 'serviceRequestDescription', component: ServiceRequestDescriptionComponent,canActivate: [AuthGuard], },
  { path: 'bookServiceManually', component: BookServiceManuallyComponent,canActivate: [AuthGuard], },
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
