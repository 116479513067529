<div class="backgroundContainer">
  <div class="container card">
    <div class="row">
      <div class="col">
        <img class="logoImage" src="../../../assets/logo/pocketmart.png" alt="pocketMard logo">
        <p style="margin-bottom: 1px;">Welcome to</p>
        <p class="quickServe"><b>Quick serve</b></p>
        <p class="dashboard">(Dashboard login for state admin, district admin, CMMU, ME Unit)</p>
        <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label"
              >User name</label
            >
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              [(ngModel)]="username"
              
              maxlength="50"
              aria-describedby="emailHelp"
              [ngModelOptions]="{standalone: true}"
              placeholder="Enter your user name"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label"
              >Password</label
            >
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="password"
              maxlength="40"
              placeholder="Enter your password"
            />
          </div>
          <button (click)="onLogin()" class="btn btn-success loginButton">Login</button>
        </form>
      </div>
      <div class="col">
        <img class="quickServeLogoImage" src="../../../assets/logo/quickServe.jpg" alt="quickServe Logo Image">
        <p class="aboutQuickServe">About Quick serve</p>
        <p class="quickServeDescription">An initiate by Kudumbashree to create employment among women in the society in the service sector. Main objective of this initiate is to provide services to public like maids, drivers, baby sitters etc. in a locality thru Kudumbashree ME units, service personals are trained and verified by Kudumbashree units.  To avail these services using an online platform Pocket Mart mobile application has been used. Quick Serve is placed as one of the services of Pocket Mart mobile application.</p>
      </div>
      
    </div>
    <div class="row">
      
    </div>
  </div>
</div>


