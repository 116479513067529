import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import Swal from 'sweetalert2';
import { PasswordService } from '../password.service';
import { QsIDsService } from '../../services/qs-ids.service';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-service-provider-description',
  templateUrl: './service-provider-description.component.html',
  styleUrls: ['./service-provider-description.component.css'],
})
export class ServiceProviderDescriptionComponent implements OnInit {
  //variable
  profileID: string = '';
  serviceProviderDescription: any;
  edit: boolean = true;

  userName: string = '';
  phoneNumber1: Number = null;
  phoneNumber2: Number = null;
  whatsAppNumber: Number = null;
  serviceName: any;
  experience: number = null;
  localBodyType: string = '';
  localBodyName: string = '';
  blockName: string = '';
  wardName: string = '';
  wardNumber: number = null;
  districtName: string = '';
  idProofName: string = '';
  idProofNumber: number = null;
  active: boolean = true;
  accountDeleted: boolean = false;
  userReview: any;
  pdfUrls: string[] = [];
  addressProofUrls: string[] = [];
  bankPassBookUrl: string[] = [];
  cdsDocumentUrl: string[] = [];
  profileImageUrl: string[] = [];
  gender: string = '';
  category: string = '';
  educationDetails: string = '';
  auxiliaryNumber: string = '';
  auxiliaryName: string = '';
  NHGNumber: string = '';
  NHGName: string = '';
  jobType: string = '';
  bankIFSCcode: string = '';
  bankAccountNumber: string = '';
  bankBranch: string = '';
  bankName: string = '';
  addressProofNumber: string = '';
  addressProofName: string = '';
  age: string = null;
  DOB: any;
  accountHolderName: string = '';

  spinnerButtonForUpdate: boolean = false;

  spinnerForServiceProviderDescription: boolean = false;

  selectedIdProofFile: File | null = null;
  selectedAddressProofFile: File | null = null;
  bankProofFile: File | null = null;
  cdsDocuments: File | null = null;

  constructor(
    private location: Location,
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService,
    private activatedRoute: ActivatedRoute,
    private QsIDsService: QsIDsService
  ) {}

  onFileSelected(event: Event, proofType: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB > 2) {
        // Show an error message (you can use your preferred method, like a toast or alert)
        console.error('File size exceeds 2MB limit');
        return;
      }

      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension !== 'pdf') {
        Swal.fire({
          icon: 'error',
          title: 'Invalid File Type',
          text: 'Please upload only PDF files for documents.',
        });
        input.value = '';
        if (proofType === 'addressProof') this.selectedAddressProofFile = null;
        else if (proofType === 'idProof') this.selectedIdProofFile = null;
        else if (proofType === 'bankPassBook') this.bankProofFile = null;
        else if (proofType === 'cdsDocuments') this.cdsDocuments = null;
        return;
      }

      const newFileName = `${this.profileID}_${proofType}.${fileExtension}`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      switch (proofType) {
        case 'idProof':
          this.selectedIdProofFile = renamedFile;
          break;
        case 'addressProof':
          this.selectedAddressProofFile = renamedFile;
          break;
        case 'bankPassBook':
          this.bankProofFile = renamedFile;
          break;
        case 'cdsDocuments':
          this.cdsDocuments = renamedFile;
          break;
      }

      console.log('Selected file:', proofType, renamedFile);
    }
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  validateAge(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  showPopup: boolean = false;
  openPopup() {
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
  }

  ngOnInit() {
    if (this.isMEUnit()) {
      this.getMEUnitDescription();
    }
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.profileID = queryParams['profileID'];
      console.log('Profile ID:', this.profileID);
    });

    if (this.profileID) {
      this.getServiceProviderDescription();
    }
  }

  MEUnit: any;
  approvalStatus: boolean;
  services: any;
  registeredULB: string;
  selectedServices: string[] = [];

  getMEUnitDescription() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // const params = new HttpParams().set('profileID', this.profileID);
    this.http.get(QS_ADMIN_URL + '/ApprovalStatus', { headers }).subscribe(
      (data) => {
        this.MEUnit = data;
        console.log(this.MEUnit);
        this.approvalStatus = this.MEUnit.isApproved;
        this.services = this.MEUnit.services;
        this.registeredULB = this.MEUnit.registeredULB;

        console.log('the approval status is: ' + this.approvalStatus);
        console.log('the services is: ' + this.services);

        // Handle success response if needed
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
        }
        // Handle error response
      }
    );
  }

  toggleServiceSelection(serviceName: string) {
    const index = this.selectedServices.indexOf(serviceName);
    if (index > -1) {
      this.selectedServices.splice(index, 1);
    } else {
      this.selectedServices.push(serviceName);
    }
  }

  isSelected(serviceName: string): boolean {
    return this.selectedServices.includes(serviceName);
  }

  submitPopup() {
    this.serviceName = [...this.selectedServices];
    this.closePopup();
  }

  // Add this function to the component class
  deleteServiceProvider() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete the service provider. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams().set('profileID', this.profileID);

        this.http
          .put(QS_ADMIN_URL + '/deleteServiceProvider', {}, { headers, params })
          .subscribe(
            (response: any) => {
              Swal.fire(
                'Deleted!',
                'The service provider has been deleted.',
                'success'
              );
              // Optionally, navigate back to the service provider list
              this.router.navigate(['/serviceProviderListings']);
            },
            (error) => {
              console.error('Error deleting service provider:', error);
              Swal.fire(
                'Error!',
                'Failed to delete the service provider.',
                'error'
              );
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              }
            }
          );
      }
    });
  }

  passwordReset() {
    const newPassword = this.passwordService.generateRandomPassword();
    const passwordReset = newPassword;
    const userAccount = this.profileID;

    Swal.fire({
      title: 'Are you sure you want to reset the password?',
      text: 'The new password is: ' + passwordReset,
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('newKeyword', passwordReset)
          .set('userAccount', userAccount);

        const options = { headers, params };
        this.http
          .put(QS_ADMIN_URL + '/passwordResetServiceProvider', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Password reset cancelled', '', 'info');
      }
    });
  }

  goBack() {
    this.location.back();
  }

  enableEdit() {
    this.edit = !this.edit;
  }

  checkphone1(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber1 = parseInt(input.value, 10);
    }
  }

  checkphone2(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber2 = parseInt(input.value, 10);
    }
  }

  checkWhatsApp(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.whatsAppNumber = parseInt(input.value, 10);
    }
  }

  checkExperience(input: HTMLInputElement) {
    if (input.value.length > 2) {
      input.value = input.value.slice(0, 2);
      this.experience = parseInt(input.value, 2);
    }
  }

  checkWardNumber(input: HTMLInputElement) {
    if (input.value.length > 5) {
      input.value = input.value.slice(0, 5);
      this.wardNumber = parseInt(input.value, 5);
    }
  }

  //to get the details
  getServiceProviderDescription() {
    this.spinnerForServiceProviderDescription = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);
    this.http
      .get(QS_ADMIN_URL + '/serviceProviderDescription', { headers, params })
      .subscribe(
        (data) => {
          this.spinnerForServiceProviderDescription = false;
          this.serviceProviderDescription = data;
          console.log(
            'serviceProviderDescription',
            this.serviceProviderDescription
          );
          this.userName = this.serviceProviderDescription.username;
          this.phoneNumber1 =
            this.serviceProviderDescription.primaryMobileNumber;
          this.phoneNumber2 =
            this.serviceProviderDescription.secondaryMobileNumber;
          this.whatsAppNumber = this.serviceProviderDescription.whatsAppNumber;
          this.serviceName = this.serviceProviderDescription.serviceName;

          this.selectedServices = [...this.serviceName];
          this.experience = this.serviceProviderDescription.experience;
          this.localBodyType =
            this.serviceProviderDescription.applicationDetails.localBodyType;
          this.localBodyName =
            this.serviceProviderDescription.applicationDetails.localBodyName;

          this.wardName =
            this.serviceProviderDescription.applicationDetails.wardName;
          this.wardNumber =
            this.serviceProviderDescription.applicationDetails.wardNumber;
          this.districtName =
            this.serviceProviderDescription.applicationDetails.districtName;
          this.idProofName =
            this.serviceProviderDescription.applicationDetails.idProofName;
          this.idProofNumber =
            this.serviceProviderDescription.applicationDetails.idProofNumber;
          this.active = this.serviceProviderDescription.active;
          this.accountDeleted = this.serviceProviderDescription.delete;
          this.pdfUrls =
            this.serviceProviderDescription.applicationDetails.idProofUrls;

          this.addressProofUrls =
            this.serviceProviderDescription.applicationDetails.addressProofUrls;
          this.addressProofNumber =
            this.serviceProviderDescription.applicationDetails.addressProofNumber;
          this.addressProofName =
            this.serviceProviderDescription.applicationDetails.addressProofName;

          this.bankName = this.serviceProviderDescription.bankDetails.bankName;
          this.bankBranch =
            this.serviceProviderDescription.bankDetails.bankBranch;
          this.bankAccountNumber =
            this.serviceProviderDescription.bankDetails.accountNumber;
          this.bankIFSCcode =
            this.serviceProviderDescription.bankDetails.IFSCcode;

          this.accountHolderName =
            this.serviceProviderDescription.bankDetails.accountHolderName;

          this.jobType = this.serviceProviderDescription.jobType;

          this.NHGName = this.serviceProviderDescription.NHGDetails?.NHGName;
          this.NHGNumber =
            this.serviceProviderDescription.NHGDetails?.NHGNumber;
          this.auxiliaryName =
            this.serviceProviderDescription.auiliaryDetails?.auiliaryName;
          this.auxiliaryNumber =
            this.serviceProviderDescription.auiliaryDetails?.auiliaryNumber;

          this.educationDetails =
            this.serviceProviderDescription.educationDetails;

          this.category = this.serviceProviderDescription.category;
          this.gender = this.serviceProviderDescription.gender;

          this.age = this.serviceProviderDescription.age;
          this.DOB = this.serviceProviderDescription.DOB;

          this.cdsDocumentUrl =
            this.serviceProviderDescription.applicationDetails.cdsDocumentsProofUrls;

          this.bankPassBookUrl =
            this.serviceProviderDescription.bankDetails.bankPassBookUrl;

          this.profileImageUrl =
            this.serviceProviderDescription.userProfileImageUrls;

          this.userReview = this.serviceProviderDescription.review;
          console.log('the pdfUrls is', this.pdfUrls);
          console.log('the addressProofUrls is', this.addressProofUrls);
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForServiceProviderDescription = false;
          }
          // Handle error response
        }
      );
  }

  // updateServiceProviderDescription() {
  //   const token = this.Authservice.getToken();
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //   const params = new HttpParams().set('profileID', this.profileID);

  //   const updatedData = {
  //     userName: this.userName,
  //     phoneNumber1: this.phoneNumber1,
  //     phoneNumber2: this.phoneNumber2,
  //     whatsAppNumber: this.whatsAppNumber,
  //     serviceName: this.selectedServices,
  //     experience: this.experience,
  //     localBodyType: this.localBodyType,
  //     localBodyName: this.localBodyName,
  //     wardName: this.wardName,
  //     wardNumber: this.wardNumber,
  //     districtName: this.districtName,
  //     idProofName: this.idProofName,
  //     idProofNumber: this.idProofNumber,
  //     active: this.active,
  //     accountDeleted: this.accountDeleted,
  //     DOB: this.DOB,
  //     age: this.age,
  //     addressProofName: this.addressProofName,
  //     addressProofNumber: this.addressProofNumber,
  //     bankName: this.bankName,
  //     bankBranch: this.bankBranch,
  //     bankAccountNumber: this.bankAccountNumber,
  //     bankIFSCcode: this.bankIFSCcode,
  //     jobType: this.jobType,
  //     NHGName: this.NHGName,
  //     NHGNumber: this.NHGNumber,
  //     auxiliaryName: this.auxiliaryName,
  //     auxiliaryNumber: this.auxiliaryNumber,
  //     educationDetails: this.educationDetails,
  //     category: this.category,
  //     gender: this.gender,
  //     accountHolderName: this.accountHolderName,
  //   };

  //   // Display SweetAlert confirmation dialog
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Do you want to update the service provider',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, update it!',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.spinnerButtonForUpdate = true;
  //       // If user clicks OK, execute the API request
  //       this.http
  //         .put(
  //           QS_ADMIN_URL + '/update/serviceProviderDescription',
  //           updatedData,
  //           { headers, params }
  //         )
  //         .subscribe(
  //           (response) => {
  //             console.log('Update successful', response);
  //             // Handle success response if needed
  //             Swal.fire(
  //               'Success!',
  //               'Service provider description updated successfully.',
  //               'success'
  //             );
  //             this.getServiceProviderDescription();
  //             this.enableEdit();
  //             // this.edit = false;
  //             this.spinnerButtonForUpdate = false;
  //           },
  //           (error) => {
  //             console.error('Error', error);
  //             // Handle error response
  //             Swal.fire(
  //               'Error!',
  //               'Failed to update service provider description.',
  //               'error'
  //             );
  //             if (error.status === 401) {
  //               console.error('Unauthorized - logging out');
  //               this.Authservice.logOut();
  //             } else {
  //               console.error('Error fetching MEUnitProfilePage:', error);
  //               this.spinnerButtonForUpdate = false;
  //             }
  //           }
  //         );
  //     }
  //   });
  // }

  private showAlert(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
      confirmButtonText: 'OK',
    });
  }

  validateDOB(): boolean {
    if (!this.DOB) {
      this.showAlert('Please enter the date of birth');
      return false;
    }

    // Regular expression to check for YYYY-MM-DD format
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateFormatRegex.test(this.DOB)) {
      this.showAlert('Please enter the date of birth in YYYY-MM-DD format');
      return false;
    }

    // Check if it's a valid date
    const date = new Date(this.DOB);
    if (isNaN(date.getTime())) {
      this.showAlert('Please enter a valid date of birth');
      return false;
    }

    // Check if the date is not in the future
    const today = new Date();
    if (date > today) {
      this.showAlert('Date of birth cannot be in the future');
      return false;
    }

    return true;
  }

  updateServiceProviderDescription() {
    if (!this.validateDOB()) {
      return;
    }

    if (!this.userName) {
      this.showAlert('Please enter the name');
      return;
    }
    if (!this.phoneNumber1 || this.phoneNumber1.toString().length !== 10) {
      this.showAlert('Please enter a valid phone number (10 digits)');
      return;
    }
    if (!this.phoneNumber2 || this.phoneNumber2.toString().length !== 10) {
      this.showAlert(
        'Please enter a valid emergency contact number (10 digits)'
      );
      return;
    }
    if (!this.whatsAppNumber || this.whatsAppNumber.toString().length !== 10) {
      this.showAlert('Please enter a valid WhatsApp number (10 digits)');
      return;
    }
    if (!this.selectedServices || this.selectedServices.length === 0) {
      this.showAlert('Please select at least one service');
      return;
    }
    if (!this.experience) {
      this.showAlert('Please enter the experience');
      return;
    }
    if (!this.educationDetails) {
      this.showAlert('Please enter the education details');
      return;
    }
    if (!this.age) {
      this.showAlert('Please enter the age');
      return;
    }

    if (!this.gender) {
      this.showAlert('Please enter the gender');
      return;
    }
    if (!this.category) {
      this.showAlert('Please enter the category');
      return;
    }
    if (!this.idProofName) {
      this.showAlert('Please enter the ID proof name');
      return;
    }
    if (!this.idProofNumber) {
      this.showAlert('Please enter the ID proof number');
      return;
    }
    if (!this.addressProofName) {
      this.showAlert('Please enter the address proof name');
      return;
    }
    if (!this.addressProofNumber) {
      this.showAlert('Please enter the address proof number');
      return;
    }
    if (!this.accountHolderName) {
      this.showAlert('Please enter the account holder name');
      return;
    }
    if (!this.bankName) {
      this.showAlert('Please enter the bank name');
      return;
    }
    if (!this.bankBranch) {
      this.showAlert('Please enter the bank branch');
      return;
    }
    if (!this.bankAccountNumber) {
      this.showAlert('Please enter the bank account number');
      return;
    }
    if (!this.bankIFSCcode) {
      this.showAlert('Please enter the bank IFSC code');
      return;
    }
    if (!this.localBodyType) {
      this.showAlert('Please enter the local body type');
      return;
    }
    if (!this.localBodyName) {
      this.showAlert('Please enter the local body name');
      return;
    }
    if (!this.wardName) {
      this.showAlert('Please enter the ward name');
      return;
    }
    if (!this.wardNumber) {
      this.showAlert('Please enter the ward number');
      return;
    }
    if (!this.districtName) {
      this.showAlert('Please enter the district name');
      return;
    }
    if (!this.jobType) {
      this.showAlert('Please select job type');
      return;
    }

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const formData = new FormData();

    // Add all the form fields to the FormData object
    formData.append('userName', this.userName);
    formData.append('phoneNumber1', this.phoneNumber1.toString());
    formData.append('phoneNumber2', this.phoneNumber2.toString());
    formData.append('whatsAppNumber', this.whatsAppNumber.toString());
    this.selectedServices.forEach((service, index) => {
      formData.append(`serviceName[${index}]`, service);
    });
    formData.append('experience', this.experience.toString());
    formData.append('localBodyType', this.localBodyType);
    formData.append('localBodyName', this.localBodyName);
    formData.append('wardName', this.wardName);
    formData.append('wardNumber', this.wardNumber.toString());
    formData.append('districtName', this.districtName);
    formData.append('idProofName', this.idProofName);
    formData.append('idProofNumber', this.idProofNumber.toString());
    formData.append('active', this.active.toString());
    formData.append('accountDeleted', this.accountDeleted.toString());
    formData.append('DOB', this.DOB);
    formData.append('age', this.age.toString());
    formData.append('addressProofName', this.addressProofName);
    formData.append('addressProofNumber', this.addressProofNumber);
    formData.append('bankName', this.bankName);
    formData.append('bankBranch', this.bankBranch);
    formData.append('bankAccountNumber', this.bankAccountNumber.toString());
    formData.append('bankIFSCcode', this.bankIFSCcode);
    formData.append('jobType', this.jobType);
    formData.append('NHGName', this.NHGName);
    formData.append('NHGNumber', this.NHGNumber);
    formData.append('auxiliaryName', this.auxiliaryName);
    formData.append('auxiliaryNumber', this.auxiliaryNumber);
    formData.append('educationDetails', this.educationDetails);
    formData.append('category', this.category);
    formData.append('gender', this.gender);
    formData.append('accountHolderName', this.accountHolderName);

    // Add the PDF files if they have been selected
    if (this.selectedIdProofFile) {
      formData.append(
        'idProofFile',
        this.selectedIdProofFile,
        this.selectedIdProofFile.name
      );
    }
    if (this.selectedAddressProofFile) {
      formData.append(
        'addressProofFile',
        this.selectedAddressProofFile,
        this.selectedAddressProofFile.name
      );
    }
    if (this.bankProofFile) {
      formData.append(
        'bankPassBook',
        this.bankProofFile,
        this.bankProofFile.name
      );
    }
    if (this.cdsDocuments) {
      formData.append(
        'cdsDocuments',
        this.cdsDocuments,
        this.cdsDocuments.name
      );
    }

    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to update the service provider',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerButtonForUpdate = true;
        // If user clicks OK, execute the API request
        this.http
          .put(QS_ADMIN_URL + '/update/serviceProviderDescription', formData, {
            headers,
            params,
          })
          .subscribe(
            (response) => {
              console.log('Update successful', response);
              // Handle success response if needed
              Swal.fire(
                'Success!',
                'Service provider description updated successfully.',
                'success'
              );
              this.getServiceProviderDescription();
              this.enableEdit();
              this.spinnerButtonForUpdate = false;
            },
            (error) => {
              console.error('Error', error);
              // Handle error response
              Swal.fire(
                'Error!',
                'Failed to update service provider description.',
                'error'
              );
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error(
                  'Error updating service provider description:',
                  error
                );
                this.spinnerButtonForUpdate = false;
              }
            }
          );
      }
    });
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isULB(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isULB();
  }
  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }

  validateAlphabets(event: KeyboardEvent): boolean {
    const input = event.key;
    const pattern = /^[a-zA-Z\s]$/; // This regex allows only alphabets and spaces
    if (!pattern.test(input)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  calculateAge() {
    if (this.DOB) {
      const today = new Date();
      const birthDate = new Date(this.DOB);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      this.age = age.toString();
    }
  }

  validateAlphanumeric(event: KeyboardEvent): boolean {
    const input = event.key;
    const pattern = /^[a-zA-Z0-9]$/; // This regex allows only alphabets and numbers
    if (!pattern.test(input)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
