<div class="container p-3 header">
  <div class="row">
    <div class="col">
      <img class="logoImage" src="../../../assets/logo/pocketmart.png" alt="">
      <div class="btn-group profileDropdown">
        <button
          type="button"
          class="btn btn-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="fa-solid fa-user"></i>&nbsp;&nbsp;{{user}}
        </button>
        <ul class="dropdown-menu">
          <li *ngIf="isMEUnit()"><a class="dropdown-item" routerLink="/profilePage">My Account</a></li>
          <li><a class="dropdown-item" routerLink="/changePassword">Change Password</a></li>
          <li><hr class="dropdown-divider" /></li>
          <li><a class="dropdown-item" (click)="onLogout()">Logout</a></li>
        </ul>
      </div>
      <button (click)="toggleFullScreen()" type="button" class="btn btn-light">
        <i class="fa-solid fa-expand"></i>
      </button>
    </div>
  </div>
</div>
