import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
import { QsIDsService } from '../../services/qs-ids.service';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-service-provider-listing',
  templateUrl: './service-provider-listing.component.html',
  styleUrls: ['./service-provider-listing.component.css'],
})
export class ServiceProviderListingComponent implements OnInit {
  //variable
  serviceProviderListing: any;
  profileID: string = '';
  spinnerForServiceProvider: boolean = false;

  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private QsIDsService: QsIDsService
  ) {}

  ngOnInit() {
    this.getServiceProviderListings();
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  getServiceProviderListings() {
    this.spinnerForServiceProvider = true
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + '/serviceProviderListing', { headers })
      .subscribe(
        (data) => {
          this.serviceProviderListing = data;
          console.log('serviceProviderListing', this.serviceProviderListing);
          this.spinnerForServiceProvider = false
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForServiceProvider = false
          }
          // Handle error response
        }
      );
  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ('0' + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

  goToServiceProviderDetails(profileID: string) {
    this.router.navigate(['/serviceProviderDescription'], {
      queryParams: {
        profileID: profileID,
      },
    });
  }


   //To check the user role
   isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isULB(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isULB();
  }
  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }
}
