<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">

  <div class="popup-overlay" *ngIf="showPopup" (click)="closePopup()"></div>
<div class="popup" *ngIf="showPopup">
  <div class="mb-3">
    <label for="phoneNumber" class="form-label">Update Service</label>
    <div class="list-container">
      <ul class="list-group">
        <li
          class="list-group-item"
          *ngFor="let service of services"
          (click)="toggleServiceSelection(service.serviceName)"
          [class.active]="isSelected(service.serviceName)"
        >
          {{ getServiceLabel(service.serviceName) }}
        </li>
      </ul>
    </div>
  </div>

  <br />
  <button (click)="closePopup()" type="button" class="btn btn-secondary">
    Cancel
  </button>
  <button
    (click)="submitPopup()"
    style="float: right"
    type="button"
    class="btn btn-success"
  >
    Submit
  </button>
</div>
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Service Provider Description</u></p>
    </div>

    <div class="col">
      <p (click)="goBack()" class="pageReference">
        Service provider listings > Service provider description
      </p>
    </div>
  </div>

  <button
    *ngIf="spinnerForServiceProviderDescription"
    class="btn btn-primary"
    type="button"
    disabled
  >
    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
    <span role="status">Loading...</span>
  </button>

  <div class="row" *ngIf="!spinnerForServiceProviderDescription">
    <div class="col-2 m-2 p-4 card">
      <img
        *ngIf="!profileImageUrl[0]"
        class="profileImage"
        style="margin-bottom: 10px"
        src="../../../assets/profileImage/profileImage.png"
        alt=""
      />

      <img
        *ngIf="profileImageUrl[0]"
        class="profileImage"
        style="margin-bottom: 10px"
        [src]="profileImageUrl[0]"
        alt=""
      />
      <div *ngFor="let name of serviceName">
        <p style="margin-top: 0" class="serviceName">
          {{ getServiceLabel(name) }}
        </p>
      </div>

      <button
        *ngIf="isMEUnit() || isSuperAdmin()"
        type="button"
        (click)="passwordReset()"
        class="btn btn-outline-primary p-1"
      >
        Reset Password
      </button>

      <button
        style="margin-top: 10px"
        *ngIf="isMEUnit() || isSuperAdmin()"
        type="button"
        (click)="deleteServiceProvider()"
        class="btn btn-outline-danger p-1 "
      >
        Delete 
      </button>
      <br />
      <div *ngFor="let url of pdfUrls">
        <a [href]="url" target="_blank">Download ID Proof PDF</a>
      </div>
      <br />
      <div *ngFor="let url of addressProofUrls">
        <a [href]="url" target="_blank">Download Address Proof PDF</a>
      </div>

      <br />
      <div *ngFor="let url of bankPassBookUrl">
        <a [href]="url" target="_blank">Download Bank Passbook PDF</a>
      </div>

      <br />
      <div *ngFor="let url of cdsDocumentUrl">
        <a [href]="url" target="_blank">Download CDS Document</a>
      </div>
      <br />
    </div>
    <div class="col m-2 p-4 card">
      <div class="row mb-1">
        <div class="col">
          <p><b>User Details</b></p>
        </div>
        <div class="col">
          <button
            *ngIf="isMEUnit() || isSuperAdmin()"
            style="float: right"
            (click)="enableEdit()"
            type="button"
            class="btn btn-secondary btn-sm"
          >
            <i class="fa-solid fa-pen-to-square"></i> Edit
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>Personal Details</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">Name</label>
                    <input
                      type="text"
                      [(ngModel)]="userName"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="edit"
                      class="form-control"
                      id="meUnitName"
                      maxlength="25"
                      placeholder="enter user name"
                      (keypress)="validateAlphabets($event)"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Phone Number</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="phoneNumber1"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="edit"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Phone Number 1"
                      #phone1
                      (input)="checkphone1(phone1)"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
              </div>
              <!---->
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Emergence Contact Number</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="phoneNumber2"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="edit"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Emergence Contact Number"
                      #phone2
                      (input)="checkphone2(phone2)"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >whatsApp Number</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="whatsAppNumber"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="edit"
                      class="form-control"
                      id="meUnitName"
                      placeholder="whatsApp Number"
                      #whatsApp
                      (input)="checkWhatsApp(whatsApp)"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
              </div>
              <!---->
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Date of Birth <span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      [(ngModel)]="DOB"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      [disabled]="edit"
                      (change)="calculateAge()"
                    />
                  </div>
                 
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">Age</label>
                    <input
                      type="text"
                      [(ngModel)]="age"
                      [ngModelOptions]="{ standalone: true }"
                      disabled
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Age"
                      #phone2
                      maxlength="2"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
                
              </div>
              <!---->
              <div class="row">
                <div class="col">
                  <!--radio button starts-->
                  <label for="formFile3" class="form-label">Gender</label>
                  <div class="row ps-4">
                    <div class="form-check">
                      <input
                        [disabled]="edit"
                        class="form-check-input"
                        type="radio"
                        name="Male"
                        id="flexRadioDefault1"
                        [(ngModel)]="gender"
                        value="Male"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Male
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        [disabled]="edit"
                        class="form-check-input"
                        type="radio"
                        name="Female"
                        id="flexRadioDefault2"
                        [(ngModel)]="gender"
                        value="Female"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Female
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        [disabled]="edit"
                        class="form-check-input"
                        type="radio"
                        name="Others"
                        id="flexRadioDefault3"
                        [(ngModel)]="gender"
                        value="Others"
                      />
                      <label class="form-check-label" for="flexRadioDefault3">
                        Others
                      </label>
                    </div>
                  </div>

                  <!--radio button ends-->
                </div>
                <div class="col">
                  <!--radio button starts-->
                  <label for="formFile3" class="form-label">Category</label>
                  <div class="row ps-4">
                    <div class="form-check">
                      <input
                        [disabled]="edit"
                        class="form-check-input"
                        type="radio"
                        name="General"
                        id="flexRadioDefault1"
                        [(ngModel)]="category"
                        value="General"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        General
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        [disabled]="edit"
                        class="form-check-input"
                        type="radio"
                        name="OBC"
                        id="flexRadioDefault2"
                        [(ngModel)]="category"
                        value="OBC"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        OBC
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        [disabled]="edit"
                        type="radio"
                        name="SC"
                        id="flexRadioDefault3"
                        [(ngModel)]="category"
                        value="SC"
                      />
                      <label class="form-check-label" for="flexRadioDefault3">
                        SC
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        [disabled]="edit"
                        class="form-check-input"
                        type="radio"
                        name="ST"
                        id="flexRadioDefault3"
                        [(ngModel)]="category"
                        value="ST"
                      />
                      <label class="form-check-label" for="flexRadioDefault3">
                        ST
                      </label>
                    </div>
                  </div>

                  <!--radio button ends-->
                </div>
              </div>
              <!---->
              <div class="row">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >Education Details</label
                  >
                  <input
                    type="text"
                    [disabled]="edit"
                    [(ngModel)]="educationDetails"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="30"
                    placeholder="Enter Education Details"
                  />
                </div>
              </div>
              <!-----------NHG details----------->
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">NHG Name</label>
                    <input
                      type="text"
                      [disabled]="edit"
                      [(ngModel)]="NHGName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="30"
                      placeholder="Enter NHG Name"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >NHG Number</label
                    >
                    <input
                      type="text"
                      [disabled]="edit"
                      [(ngModel)]="NHGNumber"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="30"
                      placeholder="Enter NHG Number"
                    />
                  </div>
                </div>
              </div>
              <!--------------------------------------->

              <!------auxiliary group details------>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Auxiliary Group Name</label
                    >
                    <input
                      type="text"
                      [disabled]="edit"
                      [(ngModel)]="auxiliaryName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="30"
                      placeholder="Enter Auxiliary Group Name"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Auxiliary Group Number</label
                    >
                    <input
                      type="text"
                      [disabled]="edit"
                      [(ngModel)]="auxiliaryNumber"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="30"
                      placeholder="Enter Auxiliary Group Number"
                    />
                  </div>
                </div>
              </div>
              <!----------------------------->
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Local Body</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="localBodyType"
                      [ngModelOptions]="{ standalone: true }"
                      disabled
                      class="form-control"
                      id="meUnitName"
                      maxlength="50"
                      placeholder="Local Body"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Local Body Name</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="localBodyName"
                      [ngModelOptions]="{ standalone: true }"
                      disabled
                      class="form-control"
                      maxlength="50"
                      id="meUnitName"
                      placeholder="Local Body Name"
                    />
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="blockName">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Block Name</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="blockName"
                      [ngModelOptions]="{ standalone: true }"
                      disabled
                      class="form-control"
                      maxlength="50"
                      id="meUnitName"
                      placeholder="Local Body Name"
                    />
                  </div>
                </div>
              </div>
              <!---->
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">Ward Name</label>
                    <input
                      type="text"
                      [(ngModel)]="wardName"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="edit"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Ward Name"
                      maxlength="30"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Ward Number</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="wardNumber"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="edit"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Ward Number"
                      #ward
                      maxlength="2"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
              </div>
              <!---->
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">District</label>
                    <input
                      type="text"
                      [(ngModel)]="districtName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="District Name"
                      maxlength="40"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <br />

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>Bank Details</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <!------bank details starts ------------>
              <div class="container p-0">
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Account Holder Name</label
                      >
                      <input
                        type="text"
                        [disabled]="edit"
                        [(ngModel)]="accountHolderName"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="30"
                        placeholder="Enter Bank Name"
                        (keypress)="validateAlphabets($event)"
                      />
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Bank Name</label
                      >
                      <input
                        type="text"
                        [disabled]="edit"
                        [(ngModel)]="bankName"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="25"
                        placeholder="Enter Bank Name"
                        (keypress)="validateAlphabets($event)"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Bank Branch</label
                      >
                      <input
                        type="text"
                        [disabled]="edit"
                        [(ngModel)]="bankBranch"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="30"
                        placeholder="Enter Branch Name"
                        (keypress)="validateAlphabets($event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Account Number</label
                      >
                      <input
                        type="text"
                        [disabled]="edit"
                        [(ngModel)]="bankAccountNumber"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="20"
                        placeholder="Enter Account Number"
                        (keypress)="validateAge($event)"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >IFSC Code</label
                      >
                      <input
                        type="text"
                        [disabled]="edit"
                        [(ngModel)]="bankIFSCcode"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="11"
                        placeholder="Enter IFSC Code"
                        (keypress)="validateAlphanumeric($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!------------------------------------>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="col">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>Job Details</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col">
                  <label for="formFile3" class="form-label">Job Details</label>
                  <div class="row ps-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        [disabled]="edit"
                        type="radio"
                        name="Full time"
                        id="flexRadioDefault1"
                        [(ngModel)]="jobType"
                        value="Full time"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Full time
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        [disabled]="edit"
                        type="radio"
                        name="Part time"
                        id="flexRadioDefault2"
                        [(ngModel)]="jobType"
                        value="Part time"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Part time
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Experience</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="experience"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="edit"
                      class="form-control"
                      id="meUnitName"
                      placeholder="experience"
                      #userExperience
                      maxlength="2"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="phoneNumber" class="form-label">Assign Service Provider</label>
                    <p>
                      <button
                        (click)="openPopup()"
                        style="width: 100%"
                        type="button"
                        class="btn btn-secondary"
                        [disabled]="edit"
                      >
                        Assign Service
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <br />
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>Personal Documents</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >ID Proof Name</label
                    >
                    <input
                      type="text"
                      [disabled]="edit"
                      [(ngModel)]="idProofName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter ID Proof Name"
                      maxlength="25"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >ID Proof Number</label
                    >
                    <input
                      type="text"
                      [disabled]="edit"
                      [(ngModel)]="idProofNumber"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter ID Proof Number"
                      maxlength="25"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Address Proof Name</label
                    >
                    <input
                      type="text"
                      [disabled]="edit"
                      [(ngModel)]="addressProofName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Address Proof Name"
                      maxlength="25"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Address Proof Number</label
                    >
                    <input
                      type="text"
                      [disabled]="edit"
                      [(ngModel)]="addressProofNumber"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Address Proof Number"
                      maxlength="25"
                    />
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="!edit">
                <div class="col">
                  <div class="mb-3">
                    <label for="formFileIdProof" class="form-label">ID Proof Document</label>
                    <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'idProof')"
                      accept="application/pdf"
                      id="formFileIdProof"
                      [disabled]="edit"
                    />
                  </div>

                  <div class="mb-3" >
                    <label for="formFileAddressProof" class="form-label">Address Proof Document</label>
                    <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'addressProof')"
                      accept="application/pdf"
                      id="formFileAddressProof"
                      [disabled]="edit"
                    />
                  </div>
            
                  <div class="mb-3">
                    <label for="formFileBankPassBook" class="form-label">Bank Pass Book</label>
                    <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'bankPassBook')"
                      accept="application/pdf"
                      id="formFileBankPassBook"
                      [disabled]="edit"
                    />
                  </div>
            
                  <div class="mb-3">
                    <label for="formFileCdsDocuments" class="form-label">CDS Registration Documents</label>
                    <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'cdsDocuments')"
                      accept="application/pdf"
                      id="formFileCdsDocuments"
                      [disabled]="edit"
                    />
                  </div>
            
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>User Review</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row" *ngIf="!spinnerForServiceProviderDescription">
                <div class="col m-2">
                  <div>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">User ID</th>
                          <th scope="col">Star</th>
                          <th scope="col">Description</th>
                        </tr>
                      </thead>
                      <tbody
                        *ngFor="let userReview of userReview; let i = index"
                      >
                        <tr>
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ userReview.reviewerID }}</td>
                          <td>
                            <span *ngIf="userReview.starRating">
                              <span
                                *ngFor="
                                  let star of [].constructor(
                                    userReview.starRating
                                  )
                                "
                              >
                                <i
                                  class="fa-solid fa-star"
                                  style="color: #ffd43b"
                                ></i>
                              </span>
                            </span>
                          </td>
                          <td>{{ userReview.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div class="row mt-4" *ngIf="!edit">
        <div class="col">
          <div
            *ngIf="spinnerButtonForUpdate"
            style="float: right"
            class="spinner-border text-success"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            *ngIf="!spinnerButtonForUpdate"
            type="button"
            (click)="updateServiceProviderDescription()"
            style="float: right"
            class="btn btn-success"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
