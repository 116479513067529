import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { PasswordService } from '../password.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { QsIDsService } from '../../services/qs-ids.service';
import { environment } from 'src/environments/environment';

const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-add-service-provider',
  templateUrl: './add-service-provider.component.html',
  styleUrls: ['./add-service-provider.component.css'],
  
})
export class AddServiceProviderComponent implements OnInit {
  MEUnit: any;
  approvalStatus: boolean = false;
  services: any;
  registeredULB: string;

  //variable for basic user name
  userName: string = '';
  phoneNumber1: number = null;
  phoneNumber2: number = null;
  whatsAppNumber: number = null;
  keyword: string = '';
  confirmKeyword: string = '';
  serviceType: string = '';
  experience: number = null;
  jobType: string = null;


  educationDetails: string = '';
  age: string = null;
  dateOfBirth: any = null;
  gender: string = 'Male';
  category: string = '';
  defaultImage: string = '../../../assets/profileImage/profileImage.png';
  selectedImage: any | null = null;
  cdsDocuments: File | null = null;

  //variables for ID proof
  idProofName: string = '';
  idProofNumber: string = '';
  selectedIdProofFile: File | null = null;

  //variables for address proof
  addressProofName: string = '';
  addressProofNumber: string = '';
  selectedAddressProofFile: File | null = null;

  //variables for the bank details
  bankName: string = '';
  bankBranch: string = '';
  bankAccountNumber: number = null;
  bankIFSCcode: string = '';
  bankProofFile: File | null = null;

  //variables for the NHG
  NHGName: string = '';
  NHGNumber: string = '';

  //varibles for auxiliary group
  auxiliaryName: string = '';
  auxiliaryNumber: string = '';

  //variables for local body
  localBodyType: string = 'Corporation'; // Default value
  localBodyName: string = '';
  wardName: string = '';
  wardNumber: string = null;
  districtName: string = '';

  //spinner variables
  spinner: boolean = false;

  showPopup = false;

  selectedServices: string[] = [];

  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService,
    private QsIDsService: QsIDsService,
    private router: Router
  ) {}

  // to select multiple services for the service provider-----
  openPopup(){
    this.showPopup = true;
  }
  closePopup() {
    this.showPopup = false;
  }
  toggleServiceSelection(serviceName: string) {
    const index = this.selectedServices.indexOf(serviceName);
    if (index === -1) {
      this.selectedServices.push(serviceName);
    } else {
      this.selectedServices.splice(index, 1);
    }

    console.log('the selected services are:', this.selectedServices);
  }

  validateAge(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  checkAgeLength() {
    // Ensure age is within 0-99 range
    if (this.age.length > 2) {
      this.age = this.age.slice(0, 2);
    }
  }

  isSelected(serviceName: string): boolean {
    return this.selectedServices.includes(serviceName);
  }

  submitPopup() {
    if(this.selectedServices && this.selectedServices.length > 0){
      this.showPopup = false;
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Choose service for the service provider',
        confirmButtonText: 'OK',
      });
    }
    
  }

  //---------------------------------------------------------------

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  ngOnInit() {
    this.adminDistrict();
    this.getMEUnitDescription();

    if (this.approvalStatus) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'To add a service provider you need to get approval from the admin',
        confirmButtonText: 'OK',
      });
    }
  }

  adminDistrict() {
    const district = this.Authservice.getDecodedDistrict();
    if (district) {
      console.log('Decoded district value:', district);
      this.districtName = district;
    } else {
      console.log('District information not available.');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong. Please login again.',
        confirmButtonText: 'OK',
      });
      // this.onLogout();
    }
  }

  onLogout() {
    this.Authservice.logOut();
  }

  checkphone1(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber1 = parseInt(input.value, 10);
    }
  }

  checkphone2(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber2 = parseInt(input.value, 10);
    }
  }

  checkWhatsApp(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.whatsAppNumber = parseInt(input.value, 10);
    }
  }

  checkExperience(input: HTMLInputElement) {
    if (input.value.length > 2) {
      input.value = input.value.slice(0, 2);
      this.experience = parseInt(input.value, 2);
    }
  }


  createPassword() {
    const newPassword = this.passwordService.generateRandomPassword();
    this.keyword = newPassword;
  }

  onFileSelected(event: Event, proofType: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB > 2) {
        Swal.fire({
          icon: 'error',
          title: 'File Size Exceeded',
          text: 'The selected file exceeds the maximum allowed size of 2MB. Please reduce the file size.',
        });
        // Clear the file input
        input.value = '';
        if (proofType === 'addressProof') {
          this.selectedAddressProofFile = null;
        } else if (proofType === 'idProof') {
          this.selectedIdProofFile = null;
        } else if (proofType === 'bankPassBook') {
          this.bankProofFile = null;
        } else if (proofType === 'cdsDocuments') {
          this.cdsDocuments = null;
        } else if (proofType === 'profileImage') {
          this.selectedImage = null;
        }
      } else {
        // Construct the new file name
        const originalName = file.name;
        const newFileName = `${Date.now()}_${proofType}_${originalName}`;

        // Create a new File object with the new name
        const renamedFile = new File([file], newFileName, { type: file.type });

        if (proofType === 'addressProof') {
          this.selectedAddressProofFile = renamedFile;
        } else if (proofType === 'idProof') {
          this.selectedIdProofFile = renamedFile;
        } else if (proofType === 'bankPassBook') {
          this.bankProofFile = renamedFile;
        } else if (proofType === 'cdsDocuments') {
          this.cdsDocuments = renamedFile;
        } else if (proofType === 'profileImage') {
          this.selectedImage = renamedFile;
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            this.defaultImage = e.target?.result as string; // Set the data URL
          };
          reader.readAsDataURL(renamedFile);
        }

        console.log('Selected file:', proofType, renamedFile);
      }
    }
  }

  getMEUnitDescription() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // const params = new HttpParams().set('profileID', this.profileID);
    this.http.get(QS_ADMIN_URL + '/ApprovalStatus', { headers }).subscribe(
      (data) => {
        this.MEUnit = data;
        console.log(this.MEUnit);
        this.approvalStatus = this.MEUnit.isApproved;
        this.services = this.MEUnit.services;
        this.registeredULB = this.MEUnit.registeredULB;

        console.log('the approval status is: ' + this.approvalStatus);
        console.log('the services is: ' + this.services);

        // Handle success response if needed
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
        }
        // Handle error response
      }
    );
  }

 

  showAlert(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  }

  // when the add service provider button is clicked
  addServiceProvider() {
   
    if (!this.selectedImage) {
      this.showAlert('Please upload a profile image');
      return;
    }
    if (!this.userName) {
      this.showAlert('Please enter the name');
      return;
    }
    if (!this.phoneNumber1) {
      this.showAlert('Please enter your phone number');
      return;
    }
    if (this.phoneNumber1.toString().length !== 10) {
      this.showAlert('Phone number must be 10 characters long');
      return;
    }
    if (!this.phoneNumber2) {
      this.showAlert('Please enter your emergency contact number');
      return;
    }
    if (this.phoneNumber2.toString().length !== 10) {
      this.showAlert('Emergency contact number must be 10 characters long');
      return;
    }
    if (!this.whatsAppNumber) {
      this.showAlert('Please enter the WhatsApp number');
      return;
    }
    if (this.whatsAppNumber.toString().length !== 10) {
      this.showAlert('WhatsApp Number number must be 10 characters long');
      return;
    }
    if (!this.keyword) {
      this.showAlert('Please enter the keyword');
      return;
    }
    if (this.selectedServices && this.selectedServices.length == 0) {
      this.showAlert('Please enter the service type');
      return;
    }
    if (!this.experience) {
      this.showAlert('Please enter the experience');
      return;
    }
    if (!this.educationDetails) {
      this.showAlert('Please enter the education details');
      return;
    }
    if (!this.age) {
      this.showAlert('Please enter the age');
      return;
    }
    if (!this.dateOfBirth) {
      this.showAlert('Please enter the date of birth');
      return;
    }
    if (!this.gender) {
      this.showAlert('Please enter the gender');
      return;
    }
    if (!this.category) {
      this.showAlert('Please enter the category');
      return;
    }
    
    if (!this.idProofName) {
      this.showAlert('Please enter the ID proof name');
      return;
    }
    if (!this.idProofNumber) {
      this.showAlert('Please enter the ID proof number');
      return;
    }
    if (!this.selectedIdProofFile) {
      this.showAlert('Please upload the ID proof file');
      return;
    }
    if (!this.addressProofName) {
      this.showAlert('Please enter the address proof name');
      return;
    }
    if (!this.addressProofNumber) {
      this.showAlert('Please enter the address proof number');
      return;
    }
    if (!this.selectedAddressProofFile) {
      this.showAlert('Please upload the address proof file');
      return;
    }

    if (!this.bankName) {
      this.showAlert('Please enter the bank name');
      return;
    }
    if (!this.bankBranch) {
      this.showAlert('Please enter the bank branch');
      return;
    }
    if (!this.bankAccountNumber) {
      this.showAlert('Please enter the bank account number');
      return;
    }
    if (!this.bankIFSCcode) {
      this.showAlert('Please enter the bank IFSC code');
      return;
    }
    if (!this.bankProofFile) {
      this.showAlert('Please upload the bank passbook');
      return;
    }

    if (!this.localBodyType) {
      this.showAlert('Please enter the local body type');
      return;
    }
    if (!this.localBodyName) {
      this.showAlert('Please enter the local body name');
      return;
    }
    if (!this.wardName) {
      this.showAlert('Please enter the ward name');
      return;
    }
    if (!this.wardNumber) {
      this.showAlert('Please enter the ward number');
      return;
    }
    if (!this.districtName) {
      this.showAlert('Please enter the district name');
      return;
    }

    if (!this.jobType) {
      this.showAlert('Please select job type');
      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Add a service provider',
      text: 'Do you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner = true;
        const timestamp = Date.now();
        const formattedTimestamp = new Date(timestamp)
          .toISOString()
          .replace(/[^\d]/g, ''); // Remove non-digit characters
        const profileID = 'USER_ID' + formattedTimestamp.slice(4, 14);
        this.spinner = true;
        const token = this.Authservice.getToken();
        console.log('The order function is called');
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        console.log('The headers are', headers);

        const formData = new FormData();
        formData.append('userName', this.userName);
        formData.append('age', this.age.toString());
        formData.append('dateOfBirth', this.dateOfBirth);
        formData.append('gender', this.gender);
        formData.append('category', this.category);
        formData.append('educationDetails', this.educationDetails);
        formData.append('phoneNumber1', this.phoneNumber1.toString());
        formData.append('phoneNumber2', this.phoneNumber2.toString());
        formData.append('whatsAppNumber', this.whatsAppNumber.toString());
        formData.append('keyword', this.keyword);
        formData.append('bankName', this.bankName);
        formData.append('bankBranch', this.bankBranch);
        formData.append('bankAccountNumber', this.bankAccountNumber.toString());
        formData.append('bankIFSCcode', this.bankIFSCcode);
        formData.append('NHGName', this.NHGName);
        formData.append('NHGNumber', this.NHGNumber);
        formData.append('auxiliaryName', this.auxiliaryName);
        formData.append('auxiliaryNumber', this.auxiliaryNumber);
        formData.append('serviceType', this.serviceType);
        formData.append('experience', this.experience.toString());
        formData.append('idProofName', this.idProofName);
        formData.append('idProofNumber', this.idProofNumber);
        formData.append('jobType', this.jobType);

        formData.append('addressProofName', this.addressProofName);
        formData.append('addressProofNumber', this.addressProofNumber);

        formData.append('localBodyType', this.localBodyType);
        formData.append('localBodyName', this.localBodyName);
        formData.append('wardName', this.wardName);
        formData.append('wardNumber', this.wardNumber.toString());
        formData.append('districtName', this.districtName);
        formData.append('profileID', profileID);
        formData.append('registeredULB', this.registeredULB);

        this.selectedServices.forEach((service, index) => {
          formData.append(`selectedServices[${index}]`, service);
        });

        if (this.selectedAddressProofFile) {
          formData.append(
            'addressProofFile',
            this.selectedAddressProofFile,
            this.selectedAddressProofFile.name
          );
        }

        if (this.selectedIdProofFile) {
          formData.append(
            'idProofFile',
            this.selectedIdProofFile,
            this.selectedIdProofFile.name
          );
        }

        if (this.bankProofFile) {
          formData.append(
            'bankPassBook',
            this.bankProofFile,
            this.bankProofFile.name
          );
        }

        if (this.cdsDocuments) {
          formData.append(
            'cdsDocuments',
            this.cdsDocuments,
            this.cdsDocuments.name
          );
        }

        if (this.selectedImage !== this.defaultImage) {
          formData.append(
            'profileImage',
            this.selectedImage,
            this.selectedImage.name
          );
        }

        this.http
          .post(QS_ADMIN_URL + '/addServiceProvider', formData, { headers })
          .subscribe(
            (data) => {
              console.log('Account created');
              this.clearTextBox();
              this.spinner = false;
              this.router.navigate(['/serviceProviderListings']);

              // Handle success response if needed
            },
            (error) => {
              console.error('Error', error);
              this.spinner = false;
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
              // Handle error response
            }
          );
      } else {
        // Handle if the user clicks 'No'
        console.log('User chose not to continue');
      }
    });
  }

  clearTextBox() {
    this.userName = '';
    this.phoneNumber1 = null;
    this.phoneNumber2 = null;
    this.whatsAppNumber = null;
    this.keyword = '';
    this.serviceType = '';
    this.experience = null;
    this.idProofName = '';
    this.addressProofName = '';
    this.addressProofNumber = '';
    this.idProofNumber = '';
    this.localBodyType = 'Corporation';
    this.localBodyName = '';
    this.wardName = '';
    this.wardNumber = null;
    this.districtName = '';
    this.confirmKeyword = '';
  }
}
