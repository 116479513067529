import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import Swal from 'sweetalert2';
import { PasswordService } from '../password.service';
import { QsIDsService } from '../../services/qs-ids.service';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-service-provider-description',
  templateUrl: './service-provider-description.component.html',
  styleUrls: ['./service-provider-description.component.css'],
})
export class ServiceProviderDescriptionComponent implements OnInit {
  //variable
  profileID: string = '';
  serviceProviderDescription: any;
  edit: boolean = true;

  userName: string = '';
  phoneNumber1: Number = null;
  phoneNumber2: Number = null;
  whatsAppNumber: Number = null;
  serviceName: any;
  experience: number = null;
  localBodyType: string = '';
  localBodyName: string = '';
  wardName: string = '';
  wardNumber: number = null;
  districtName: string = '';
  idProofName: string = '';
  idProofNumber: number = null;
  active: boolean = true;
  accountDeleted: boolean = false;
  userReview: any;
  pdfUrls: string[] = [];
  addressProofUrls: string[] = [];
  bankPassBookUrl: string[] = [];
  cdsDocumentUrl: string[] = [];
  profileImageUrl: string[] = [];
  gender: string = '';
  category: string = '';
  educationDetails: string = '';
  auxiliaryNumber: string = '';
  auxiliaryName: string = '';
  NHGNumber: string = '';
  NHGName: string = '';
  jobType: string = '';
  bankIFSCcode: string = '';
  bankAccountNumber: string = '';
  bankBranch: string = '';
  bankName: string = '';
  addressProofNumber: string = '';
  addressProofName: string = '';
  age: number = null;
  DOB: any;

  spinnerButtonForUpdate: boolean = false;

  spinnerForServiceProviderDescription: boolean = false;

  constructor(
    private location: Location,
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService,
    private activatedRoute: ActivatedRoute,
    private QsIDsService: QsIDsService
  ) {}

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  validateAge(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.profileID = queryParams['profileID'];
      console.log('Profile ID:', this.profileID);
    });

    if (this.profileID) {
      this.getServiceProviderDescription();
    }
  }

  passwordReset() {
    const newPassword = this.passwordService.generateRandomPassword();
    const passwordReset = newPassword;
    const userAccount = this.profileID;

    Swal.fire({
      title: 'Are you sure you want to reset the password?',
      text: 'The new password is: ' + passwordReset,
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('newKeyword', passwordReset)
          .set('userAccount', userAccount);

        const options = { headers, params };
        this.http
          .put(QS_ADMIN_URL + '/passwordResetServiceProvider', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Password reset cancelled', '', 'info');
      }
    });
  }

  goBack() {
    this.location.back();
  }

  enableEdit() {
    this.edit = false;
  }

  checkphone1(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber1 = parseInt(input.value, 10);
    }
  }

  checkphone2(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber2 = parseInt(input.value, 10);
    }
  }

  checkWhatsApp(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.whatsAppNumber = parseInt(input.value, 10);
    }
  }

  checkExperience(input: HTMLInputElement) {
    if (input.value.length > 2) {
      input.value = input.value.slice(0, 2);
      this.experience = parseInt(input.value, 2);
    }
  }

  checkWardNumber(input: HTMLInputElement) {
    if (input.value.length > 5) {
      input.value = input.value.slice(0, 5);
      this.wardNumber = parseInt(input.value, 5);
    }
  }

  //to get the details
  getServiceProviderDescription() {
    this.spinnerForServiceProviderDescription = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);
    this.http
      .get(QS_ADMIN_URL + '/serviceProviderDescription', { headers, params })
      .subscribe(
        (data) => {
          this.spinnerForServiceProviderDescription = false;
          this.serviceProviderDescription = data;
          console.log(
            'serviceProviderDescription',
            this.serviceProviderDescription
          );
          this.userName = this.serviceProviderDescription.username;
          this.phoneNumber1 =
            this.serviceProviderDescription.primaryMobileNumber;
          this.phoneNumber2 =
            this.serviceProviderDescription.secondaryMobileNumber;
          this.whatsAppNumber = this.serviceProviderDescription.whatsAppNumber;
          this.serviceName = this.serviceProviderDescription.serviceName;
          this.experience = this.serviceProviderDescription.experience;
          this.localBodyType =
            this.serviceProviderDescription.applicationDetails.localBodyType;
          this.localBodyName =
            this.serviceProviderDescription.applicationDetails.localBodyName;
          this.wardName =
            this.serviceProviderDescription.applicationDetails.wardName;
          this.wardNumber =
            this.serviceProviderDescription.applicationDetails.wardNumber;
          this.districtName =
            this.serviceProviderDescription.applicationDetails.districtName;
          this.idProofName =
            this.serviceProviderDescription.applicationDetails.idProofName;
          this.idProofNumber =
            this.serviceProviderDescription.applicationDetails.idProofNumber;
          this.active = this.serviceProviderDescription.active;
          this.accountDeleted = this.serviceProviderDescription.delete;
          this.pdfUrls =
            this.serviceProviderDescription.applicationDetails.idProofUrls;

          this.addressProofUrls =
            this.serviceProviderDescription.applicationDetails.addressProofUrls;
          this.addressProofNumber =
            this.serviceProviderDescription.applicationDetails.addressProofNumber;
          this.addressProofName =
            this.serviceProviderDescription.applicationDetails.addressProofName;

          this.bankName = this.serviceProviderDescription.bankDetails.bankName;
          this.bankBranch =
            this.serviceProviderDescription.bankDetails.bankBranch;
          this.bankAccountNumber =
            this.serviceProviderDescription.bankDetails.accountNumber;
          this.bankIFSCcode =
            this.serviceProviderDescription.bankDetails.IFSCcode;

          this.jobType = this.serviceProviderDescription.jobType;

          this.NHGName = this.serviceProviderDescription.NHGDetails?.NHGName;
          this.NHGNumber =
            this.serviceProviderDescription.NHGDetails?.NHGNumber;
          this.auxiliaryName =
            this.serviceProviderDescription.auiliaryDetails?.auiliaryName;
          this.auxiliaryNumber =
            this.serviceProviderDescription.auiliaryDetails?.auiliaryNumber;

          this.educationDetails =
            this.serviceProviderDescription.educationDetails;

          this.category = this.serviceProviderDescription.category;
          this.gender = this.serviceProviderDescription.gender;

          this.age = this.serviceProviderDescription.age;
          this.DOB = this.serviceProviderDescription.DOB;

          this.cdsDocumentUrl =
            this.serviceProviderDescription.applicationDetails.cdsDocumentsProofUrls;

          this.bankPassBookUrl =
            this.serviceProviderDescription.bankDetails.bankPassBookUrl;

            this.profileImageUrl =
            this.serviceProviderDescription.userProfileImageUrls;

          this.userReview = this.serviceProviderDescription.review;
          console.log('the pdfUrls is', this.pdfUrls);
          console.log('the addressProofUrls is', this.addressProofUrls);
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForServiceProviderDescription = false;
          }
          // Handle error response
        }
      );
  }

  updateServiceProviderDescription() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const updatedData = {
      userName: this.userName,
      phoneNumber1: this.phoneNumber1,
      phoneNumber2: this.phoneNumber2,
      whatsAppNumber: this.whatsAppNumber,
      serviceName: this.serviceName,
      experience: this.experience,
      localBodyType: this.localBodyType,
      localBodyName: this.localBodyName,
      wardName: this.wardName,
      wardNumber: this.wardNumber,
      districtName: this.districtName,
      idProofName: this.idProofName,
      idProofNumber: this.idProofNumber,
      active: this.active,
      accountDeleted: this.accountDeleted,
      DOB: this.DOB,
      age: this.age,
      addressProofName: this.addressProofName,
      addressProofNumber: this.addressProofNumber,
      bankName: this.bankName,
      bankBranch: this.bankBranch,
      bankAccountNumber: this.bankAccountNumber,
      bankIFSCcode: this.bankIFSCcode,
      jobType: this.jobType,
      NHGName: this.NHGName,
      NHGNumber: this.NHGNumber,
      auxiliaryName: this.auxiliaryName,
      auxiliaryNumber: this.auxiliaryNumber,
      educationDetails: this.educationDetails,
      category: this.category,
      gender: this.gender,
    };

    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to update the service provider',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerButtonForUpdate = true;
        // If user clicks OK, execute the API request
        this.http
          .put(
            QS_ADMIN_URL + '/update/serviceProviderDescription',
            updatedData,
            { headers, params }
          )
          .subscribe(
            (response) => {
              console.log('Update successful', response);
              // Handle success response if needed
              Swal.fire(
                'Success!',
                'Service provider description updated successfully.',
                'success'
              );
              this.getServiceProviderDescription();
              this.edit = false;
              this.spinnerButtonForUpdate = false;
            },
            (error) => {
              console.error('Error', error);
              // Handle error response
              Swal.fire(
                'Error!',
                'Failed to update service provider description.',
                'error'
              );
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
                this.spinnerButtonForUpdate = false;
              }
            }
          );
      }
    });
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isULB(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isULB();
  }
  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }
}
