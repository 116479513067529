import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-ulb-listings',
  templateUrl: './ulb-listings.component.html',
  styleUrls: ['./ulb-listings.component.css'],
})
export class UlbListingsComponent {
  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient
  ) {}

  ulbListings: any;
  spinnerForListingPage: boolean = false;


  


  ngOnInit() {
    this.initializeBasedOnUserRole();
  }

  currentPage: number = 1;
  totalPages: number = 1;
  pageSize: number = 30;

  initializeBasedOnUserRole() {
    if (this.isDistrictAdmin()) {
      this.getULBListings();
    } else if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.getULBListingsForStateAdmin();
    }
  }

  getULBListings(page: number = 1) {
    this.spinnerForListingPage = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('page', page.toString()).set('limit', this.pageSize.toString());

    this.http
      .get(QS_ADMIN_URL + '/ULBListings', { headers, params }) // Changed from post to get
      .subscribe(
        (data: any) => {
          this.ulbListings = data.ulbListings;
          this.totalPages = data.totalPages;
          this.currentPage = data.currentPage;
          console.log('ulbListings', this.ulbListings);
          this.spinnerForListingPage = false;
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForListingPage = false;
          }
        }
      );
  }

  
  getULBListingsForStateAdmin(page: number = 1) {
    this.spinnerForListingPage = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('page', page.toString()).set('limit', this.pageSize.toString());

    this.http
      .get(QS_ADMIN_URL + '/ULBListingsForStateAdmin', { headers, params }) // Changed from post to get
      .subscribe(
        (data: any) => {
          this.ulbListings = data.ulbListings;
          this.totalPages = data.totalPages;
          this.currentPage = data.currentPage;
          console.log('ulbListings', this.ulbListings);
          this.spinnerForListingPage = false;
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForListingPage = false;
          }
          // Handle error response
        }
      );
  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ('0' + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isULB(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isULB();
  }
  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }
}
