<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>CMMU Units Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > CMMU Units</p>
    </div>
  </div>

  <br />

  <button *ngIf="spinnerForListingPage" class="btn btn-primary" type="button" disabled>
    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    Loading...
  </button>

  <div class="row" *ngIf="!spinnerForListingPage">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">ProfileID</th>
            <th scope="col">E-Mail</th>
            <th scope="col">District</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Account Status</th>
          </tr>
        </thead>
        <tbody *ngFor="let ulbListings of ulbListings; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ ulbListings.name }}</td>
            <td>{{ ulbListings.profileID }}</td>
            <td>{{ ulbListings.userAccount }}</td>
            <td>{{ ulbListings.district }}</td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUTCToIST(ulbListings.registeredDate) }}
            </td>
            <td>
              <span
                [class.active]="!ulbListings.accountDelete"
                [class.pending]="ulbListings.accountDelete"
              >
                {{ !ulbListings.accountDelete ? "Active" : "Deleted" }}
                </span
              >

        
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
