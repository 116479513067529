import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { PasswordService } from '../password.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-create-ulb',
  templateUrl: './create-ulb.component.html',
  styleUrls: ['./create-ulb.component.css'],
})
export class CreateULBComponent implements OnInit {
  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService
  ) {}

  ngOnInit() {
    this.adminDistrict();
    this.getULBList();
  }

  //variables
  district: string = '';
  eMailID: string = '';
  keyword: string = '';
  spinner: boolean = false;
  userName: string = '';
  ULBList: any;
  spinnerForCreateCMMUUnit: boolean = false;
  emailError: string = '';

  createPassword() {
    // const newPassword = this.generateRandomPassword();
    const newPassword = this.passwordService.generateRandomPassword();
    this.keyword = newPassword;
  }

  validateEmail(control: AbstractControl): ValidationErrors | null {
    const email = control.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      return { required: 'Email is required' };
    }

    if (!emailRegex.test(email)) {
      return { invalidFormat: 'Invalid email format' };
    }

    return null;
  }

  adminDistrict() {
    const district = this.Authservice.getDecodedDistrict();
    if (district) {
      console.log('Decoded district value:', district);
      this.district = district;
    } else {
      console.log('District information not available.');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong. Please login again.',
        confirmButtonText: 'OK',
      });
      this.onLogout();
    }
  }

  onLogout() {
    this.Authservice.logOut();
  }


  createdMailID: string = '';
  createdPassword: string = '';
  createULB() {
    this.emailError = '';
    if (!this.district || !this.eMailID || !this.keyword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter all the fields',
      });
      return;
    }

    const emailValidation = this.validateEmail({
      value: this.eMailID,
    } as AbstractControl);
    if (emailValidation) {
      this.emailError =
        emailValidation['required'] || emailValidation['invalidFormat'];
      return;
    }
    
    Swal.fire({
      icon: 'info',
      title: 'Create CMMU',
      text: 'Do you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerForCreateCMMUUnit = true;
        const timestamp = Date.now();
        const formattedTimestamp = new Date(timestamp)
          .toISOString()
          .replace(/[^\d]/g, ''); // Remove non-digit characters
        const profileID = 'USER_ID' + formattedTimestamp;
        this.spinner = true;
        const token = this.Authservice.getToken();
        console.log('the order function is called');
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        console.log('the headers are', headers);

        const params = new HttpParams()
          .set('district', this.district)
          .set('eMailID', this.eMailID)
          .set('keyword', this.keyword)
          .set('profileID', profileID)
          .set('name', this.userName);

        this.http
          .post(QS_ADMIN_URL + '/createULB', {}, { headers, params })
          .subscribe(
            (data) => {
              console.log('account created');

              this.spinner = false;
              this.createdMailID = this.eMailID;
              this.createdPassword = this.keyword;
              this.getULBList();
              this.openEmail();
              this.clearTextBox();
              this.spinnerForCreateCMMUUnit = false;
              // Handle success response if needed
            },
            (error) => {
              console.error('Error', error);
              this.spinner = false;
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              }
              else if(error.status === 400){
                alert('Error in creating an account')
                this.spinnerForCreateCMMUUnit = false;

              }
               else {
                console.error('Error fetching MEUnitProfilePage:', error);
                this.spinnerForCreateCMMUUnit = false;
              }
              // Handle error response
            }
          );
      } else {
        // Handle if the user clicks 'No'
        console.log('User chose not to continue');
      }
    });
  }

  closeTheCard(){
    this.createdMailID = '';
    this.createdPassword = '';
  }


  openEmail() {
    const recipient = this.eMailID;

    const subject = 'Password';
    const body = `  Hi,
    
    You have successfully registered in PocketMart Quick serve.

    Your username : ${recipient}
    Your temporary keyword is : ${this.keyword}.

    Please login to change your password.

    Thanks and regards.
    PocketMart Team.
    
    `;

    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${encodeURIComponent(
      body
    )}`;
    window.location.href = mailtoLink;
  }

  

  //to get the details
  getULBList() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + '/ULBLists', { headers }) // Changed from post to get
      .subscribe(
        (data) => {
          this.ULBList = data;
          console.log('ULBList', this.ULBList);

          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
          }
          // Handle error response
        }
      );
  }

  clearTextBox() {
    // this.district = '';
    this.eMailID = '';
    this.userName = '';
    this.keyword = '';
  }

  passwordReset(userName: string) {
    const newPassword = this.passwordService.generateRandomPassword();
    const passwordReset = newPassword;
    const username = userName;

    Swal.fire({
      title: 'Are you sure you want to reset the password?',
      text: 'The new password is: ' + passwordReset,
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('newKeyword', passwordReset)
          .set('username', username);

        const options = { headers, params };
        this.http.put(QS_ADMIN_URL + '/passwordRestULB', {}, options).subscribe(
          (data: any) => {
            Swal.fire(data.message);
          },
          (error) => {
            console.log(error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
            }
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Password reset cancelled', '', 'info');
      }
    });
  }

  accountDelete(userName: string) {
    const accountDelete = true;
    const username = userName;

    Swal.fire({
      title: 'Are you sure you want to delete the account?',
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('accountDelete', accountDelete)
          .set('username', username);

        const options = { headers, params };
        this.http
          .put(QS_ADMIN_URL + '/deleteAccountULB', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
              this.getULBList();
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('delete account cancelled', '', 'info');
      }
    });
  }
}
