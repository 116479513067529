<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Service Provider Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > Service provider listings</p>
    </div>
  </div>

  <br />

  <!-- <div *ngIf="spinnerForServiceProvider" class="spinner-grow text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div> -->

  <button
    *ngIf="spinnerForServiceProvider"
    class="btn btn-primary"
    type="button"
    disabled
  >
    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
    <span role="status">Loading...</span>
  </button>

  <div class="row" *ngIf="!spinnerForServiceProvider">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Reg Status</th>

            <th scope="col">Account Status</th>
            <th scope="col">Service</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody
          *ngFor="
            let serviceProviderListing of serviceProviderListing;
            let i = index
          "
        >
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ serviceProviderListing.username }}</td>
            <td>
              <i
                class="fa-solid fa-phone"
                *ngIf="serviceProviderListing.primaryMobileNumber"
              ></i>
              {{ serviceProviderListing.primaryMobileNumber }}
            </td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUTCToIST(serviceProviderListing.registeredDate) }}
            </td>
            <td>
              <span
                [class.active]="serviceProviderListing.active"
                [class.inactive]="!serviceProviderListing.active"
                >{{
                  serviceProviderListing.active ? "Active" : "Inactive"
                }}</span
              >
            </td>

            <td>
              <span [ngClass]="{
                'status-activeStatus': !serviceProviderListing.delete,
                'status-deletedStatus': serviceProviderListing.delete
              }">
                {{ serviceProviderListing.delete ? 'Deleted' : 'Active' }}
              </span>
            </td>
            <td>
              <div *ngFor="let name of serviceProviderListing.serviceName">
                <p style="margin-top: 0" class="serviceName">
                  {{ getServiceLabel(name) }}
                </p>
              </div>

              <!-- {{ getServiceLabel(serviceProviderListing.serviceName) }}</td> -->
            </td>

            <td>
              <button
                type="button"
                (click)="
                  goToServiceProviderDetails(serviceProviderListing.profileID)
                "
                class="btn btn-link"
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
