import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent {

  constructor(private authService: AuthServiceService,private router: Router){}

  username: string = '';
  password: string = '';
  error: string = '';

  onLogin(): void {
    if (this.username === '' || this.password === '') {
      return alert('All input fields are required');
    }
    // Perform login logic here
    const user = {
      username: this.username,
      password: this.password,
    };

    console.log(user);
    this.authService.login(user).subscribe(
      (res) => {
        console.log(res, 'res');
        if (res.message == 'Invalid credentials') {
          this.clearFields();
          return alert('Invalid credentials');
        }
        if (res.message == 'User not found') {
          this.clearFields();
          return alert('User not found');
        } else {
          const expiresIn = res.expiresIn;
          console.log('got the token')
          this.authService.saveToken(res.token, expiresIn);
          this.router.navigate(['/analyticsPage']);
        }
      },
      (error) => {
        this.error = error.error.message;
      }
    );
  }

  clearFields(){
    this.username = '';
    this.password = '';
  }
 

}
