<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="analytics"><u>Analytics</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > Analytics</p>
    </div>
  </div>

  <!--calendar-->
  <div class="row">
    <div class="col-6">
      <mat-form-field appearance="fill" style="margin-right: 10px">
        <mat-label>Enter your start date</mat-label>
        <input
          matInput
          [matDatepicker]="firstPicker"
          [(ngModel)]="startDate"
          (dateChange)="onDateChange($event, 'First Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="firstPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #firstPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Enter your end date</mat-label>
        <input
          matInput
          [matDatepicker]="secondPicker"
          [(ngModel)]="endDate"
          (dateChange)="onDateChange($event, 'Second Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="secondPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #secondPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col" *ngIf="isStateAdmin() || isSuperAdmin()">
      <select
        class="form-select"
        id="districtSelect"
        (change)="onDistrictSelected($event)"
        aria-label="Default select example"
      >
        <option value="" disabled selected>Open this select district</option>
        <!-- Use *ngFor to loop through the districts array and create an option for each item -->
        <option *ngFor="let district of districts" [value]="district.value">
          {{ district.label }}
        </option>
      </select>
    </div>

    <div class="col">
      <button
        *ngIf="!spinnerForSearch"
        type="button"
        (click)="search()"
        class="btn btn-success"
      >
        Search
      </button>
      <div
        *ngIf="spinnerForSearch"
        class="spinner-border text-success"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col"></div>
    <div class="col">
      <button
        *ngIf="!spinnerForPDFDownload"
        (click)="downloadAnalytics()"
        style="margin-left: 15px; float: right"
        type="button"
        class="btn btn-secondary"
      >
        PDF download
      </button>
      <div
        *ngIf="spinnerForPDFDownload"
        style="margin-left: 15px; float: right"
        class="spinner-border text-secondary"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <button
        *ngIf="!spinnerForExcelDownload"
        (click)="downloadExcelAnalytics()"
        style="margin-left: 15px; float: right"
        type="button"
        class="btn btn-warning"
      >
        Excel download
      </button>
      <div
        *ngIf="spinnerForExcelDownload"
        style="margin-left: 15px; float: right"
        class="spinner-border text-warning"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <!--cards-->
  <div class="row">
    <div *ngIf="!isMEUnit()" class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total MEUnits Registered</p>
      <p class="cardHeading">{{ approvedMEUnits }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-success" style="width: 100%"></div>
      </div>
    </div>

    <div *ngIf="!isMEUnit()" class="col card p-3 m-2">
      <p style="margin-bottom: 5">Approval Pending MEUnits</p>
      <p class="cardHeading">{{ notApprovedMEUnits }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Danger example"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-danger" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total Service Request</p>
      <p class="cardHeading">{{ totalServiceRequests }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Warning example"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-warning" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Cancelled Service Request</p>
      <p class="cardHeading">{{ cancelledServiceRequests }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Info example"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-info" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2" *ngIf="isMEUnit()">
      <p style="margin-bottom: 5">Active Service Providers</p>
      <p class="cardHeading">{{ activeServiceProviders }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-success" style="width: 100%"></div>
      </div>
    </div>
    <div class="col card p-3 m-2" *ngIf="isMEUnit()">
      <p style="margin-bottom: 5">Inactive Service Providers</p>
      <p class="cardHeading">{{ inActiveServiceProviders }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Danger example"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-danger" style="width: 100%"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="!isMEUnit()" class="col card p-3 m-2">
      <p style="margin-bottom: 5">Active Service Providers</p>
      <p class="cardHeading">{{ activeServiceProviders }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-success" style="width: 100%"></div>
      </div>
    </div>

    <div *ngIf="!isMEUnit()" class="col card p-3 m-2">
      <p style="margin-bottom: 5">Inactive Service Providers</p>
      <p class="cardHeading">{{ inActiveServiceProviders }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Danger example"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-danger" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total Revenue</p>
      <p class="cardHeading">{{ totalAmount }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Info example"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-info" style="width: 100%"></div>
      </div>
    </div>

    <div class="col p-3 m-2"></div>
    <div class="col p-3 m-2" *ngIf="isMEUnit()"></div>
    <div class="col p-3 m-2" *ngIf="isMEUnit()"></div>
  </div>

  <hr />

  <div class="row">
    <div class="col">
      <p class="analytics">
        <u>Service provider details</u
        ><button
          *ngIf="!spinnerForServiceProvider"
          (click)="downloadServiceProviderExcel()"
          style="margin-bottom: 15px; float: right"
          type="button"
          class="btn btn-warning"
        >
          Excel download
        </button>
      </p>

      <div
        *ngIf="spinnerForServiceProvider"
        style="margin-bottom: 15px; float: right"
        class="spinner-border text-warning"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Service</th>
            <th scope="col">Service Completed</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody
          *ngFor="
            let serviceProviderListing of serviceProviderListing;
            let i = index
          "
        >
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td
              (click)="goToServiceProviderDescription(serviceProviderListing.profileID)">
              {{ serviceProviderListing.username }}
            </td>
            <td>
              <i class="fa-solid fa-phone"></i>
              {{ serviceProviderListing.primaryMobileNumber }}
            </td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUTCToIST(serviceProviderListing.registeredDate) }}
            </td>

            <td>
              <span *ngIf="serviceProviderListing.serviceType">{{
                getServiceLabel(serviceProviderListing.serviceType)
              }}</span>
            </td>
            <td>
              {{ serviceProviderListing.completedOrderCount }}
            </td>
            <td>
              {{ serviceProviderListing.amountForServiceProvider }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
