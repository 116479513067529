<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Create District Admin</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > Create District Admin</p>
    </div>
  </div>

  <div class="row">
    <div class="col-10 card createCard m-2 p-4">

      <div class="row ">
        <div class="col p-4">
          <div class="mb-3">
            <label for="districtSelect" class="form-label">Select District</label>
            <select class="form-select" id="districtSelect" (change)="onDistrictSelected($event)" aria-label="Default select example">
              <option value="" disabled selected>Open this select menu</option>
              <!-- Use *ngFor to loop through the districts array and create an option for each item -->
              <option *ngFor="let district of districts" [value]="district.value">{{ district.label }}</option>
            </select>
          </div>
          
          <div class="mb-3">
            <label for="email" class="form-label">Enter e-mail id</label>
            <input
              type="email"
              class="form-control"
              [(ngModel)]="eMailID"
              [ngModelOptions]="{ standalone: true }"
              id="email"
              maxlength="60"
              placeholder="Enter e-mail id"
            />
          </div>
        </div>
        <div class="col p-4">
          <div class="mb-3">
            <label for="phoneNumber" class="form-label">Generate Keyword</label>
            <button
              type="button"
              class="btn btn-primary"
              (click)="createPassword()"
              style="width: 100%"
            >
              Generate keyword button
            </button>
          </div>
  
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Keyword</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="keyword"
              [ngModelOptions]="{ standalone: true }"
              id="exampleInputPassword1"
              placeholder="Enter keyword"
              maxlength="10"
            />
          </div>

          <div *ngIf="spinnerForCreateDistrictAdminButton" style="float: right;" class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
          *ngIf="!spinnerForCreateDistrictAdminButton"
            type="submit"
            (click)="createDistrictAdmin()"
            class="btn btn-success createButton"
          >
            Create District Admin
          </button>
        </div>
      </div>
      
       
      
      
    </div>
    <div class="col"></div>



   
  </div>
  <div class="row">
    <div class="col card m-2 p-4">
      <p class="tableHeading">District admin list</p>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">District</th>
            <th scope="col">Account Status</th>
            <th scope="col">Reset Password</th>
            <th scope="col">Delete Account</th>
          </tr>
        </thead>
        <tbody *ngFor="let districtAdmin of districtAdmin; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ districtAdmin.userAccount }}</td>
            <td><i class="fa-solid fa-location-dot"></i> {{ districtAdmin.district }}</td>
            <td>
              <span [class.active]="!districtAdmin.accountDelete" [class.deleted]="districtAdmin.accountDelete">
                {{ districtAdmin.accountDelete ? "Deleted" : "Active" }}
              </span>
            </td>
            <td>
              <button [disabled]="districtAdmin.accountDelete" (click)="passwordReset(districtAdmin.userAccount)"  type="button" class="btn btn-outline-primary p-1">Reset Password</button>
            </td>
            <td>
              <button [disabled]="districtAdmin.accountDelete" 
              (click)="accountDelete(districtAdmin.userAccount)"  type="button" class="btn btn-danger"><i class="fa-solid fa-trash-can"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
