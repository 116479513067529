<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Create CMMU</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > create CMMU</p>
    </div>
  </div>

  <div class="row">
    <div class="col-10 card createCard m-2 p-4">
      <form>
        <div class="row">
          <div class="col p-2">
            <div class="mb-3">
              <label for="Username" class="form-label">District</label>
              <input
                type="text"
                style="background-color: rgb(206, 203, 203); border-color: rgb(206, 203, 203);"
                [(ngModel)]="district"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="exampleInputPassword1"
                disabled
                placeholder="District Name"
                maxlength="60"
              />
            </div>
    
            <div class="mb-3">
              <label for="email" class="form-label">Enter e-mail id</label>
              <input
                type="email"
                [(ngModel)]="eMailID"
                [ngModelOptions]="{ standalone: true }"
                maxlength="60"
                class="form-control"
                id="email"
                placeholder="Enter e-mail id"
              />
            </div>
    
            <div class="mb-3">
              <label for="Username" class="form-label">CMMU Name</label>
              <input
                type="text"
                [(ngModel)]="userName"
                [ngModelOptions]="{ standalone: true }"
                maxlength="60"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter CMMU Name"
              />
            </div>
          </div>
          <div class="col p-2">
            <div class="mb-3">
              <label for="phoneNumber" class="form-label">Generate Keyword</label>
              <button
                type="button"
                class="btn btn-primary"
                (click)="createPassword()"
                style="width: 100%"
              >
                Generate keyword button
              </button>
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Keyword</label>
              <input
                type="text"
                [(ngModel)]="keyword"
                [ngModelOptions]="{ standalone: true }"
                maxlength="10"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter Keyword"
              />
            </div>
            <br><br>
            <div *ngIf="spinnerForCreateCMMUUnit" style="float: right;" class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <button
            *ngIf="!spinnerForCreateCMMUUnit"
              type="submit"
              (click)="createULB()"
              class="btn btn-success createButton"
            >
              Create CMMU Unit
            </button>
          </div>
        </div>

        
        
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col card m-2 p-4">
      <p class="tableHeading">CMMU Lists</p>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Username</th>
            <th scope="col">District</th>
            <th scope="col">Status</th>
            <th scope="col">Reset Password</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ULBList of ULBList; let i = index">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ ULBList.name }}</td>
            <td>{{ ULBList.userAccount }}</td>
            <td>
              <i class="fa-solid fa-location-dot"></i> {{ ULBList.district }}
            </td>
            <td>
              <span
                [class.active]="!ULBList.accountDelete"
                [class.deleted]="ULBList.accountDelete"
              >
                {{ ULBList.accountDelete ? "Deleted" : "Active" }}
              </span>
            </td>
            <td>
              <button
                [disabled]="ULBList.accountDelete"
                type="button"
                (click)="passwordReset(ULBList.userAccount)"
                class="btn btn-outline-primary p-1"
              >
                Reset Password
              </button>
            </td>
            <td>
              <button
                [disabled]="ULBList.accountDelete"
                (click)="accountDelete(ULBList.userAccount)"
                type="button"
                class="btn btn-danger"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
