import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-me-unit-listings',
  templateUrl: './me-unit-listings.component.html',
  styleUrls: ['./me-unit-listings.component.css'],
})
export class MeUnitListingsComponent implements OnInit {
  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.initializeBasedOnUserRole();

  }
  //variables
  MEUnitListings: any;
  spinnerForMEUnitListings : boolean = false;

  initializeBasedOnUserRole() {
    if (this.isULB()) {
      this.getMEUnitsListings();
    } else if (this.isDistrictAdmin()) {
      this.getMEUnitsListingsForDistrictAdmin();
    } else if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.getMEUnitsListingsForStateAdmin();
    }
  }



  goToMeUnitDescription(profileID: string) {
    this.router.navigate(['/meUnitDescription'], {
      queryParams: {
        profileID: profileID,
      },
    });
  }

  //to get the details
  getMEUnitsListings() {
    this.spinnerForMEUnitListings = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + '/MEUnitListings', { headers }) // Changed from post to get
      .subscribe(
        (data) => {
          this.spinnerForMEUnitListings = false;
          this.MEUnitListings = data;
          console.log('MEUnitListings', this.MEUnitListings);
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForMEUnitListings = false;
          }
          // Handle error response
        }
      );
  }

  getMEUnitsListingsForStateAdmin() {
    this.spinnerForMEUnitListings = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + '/MEUnitListingsForStateAdmin', { headers }) // Changed from post to get
      .subscribe(
        (data) => {
          this.spinnerForMEUnitListings = false;
          this.MEUnitListings = data;
          console.log('MEUnitListings', this.MEUnitListings);
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForMEUnitListings = false;
          }
          // Handle error response
        }
      );
  }

  getMEUnitsListingsForDistrictAdmin() {
    this.spinnerForMEUnitListings = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + '/MEUnitListingsForDistrictAdmin', { headers }) // Changed from post to get
      .subscribe(
        (data) => {
          this.spinnerForMEUnitListings = false;
          this.MEUnitListings = data;
          console.log('MEUnitListings', this.MEUnitListings);
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForMEUnitListings = false;
          }
          // Handle error response
        }
      );
  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ('0' + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

    //To check the user role
    isSuperAdmin(): boolean {
      return (
        this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
      );
    }
    isStateAdmin(): boolean {
      return (
        this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
      );
    }
    isDistrictAdmin(): boolean {
      return (
        this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
      );
    }
    isULB(): boolean {
      return this.Authservice.isAuthenticated() && this.Authservice.isULB();
    }
    isMEUnit(): boolean {
      return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
    }
}
