import { Component, ElementRef, Input, ViewChild, OnInit, AfterViewInit, Output, EventEmitter, NgZone } from '@angular/core';


export interface PlaceSearchResult{
  address: string;
  // location?: google.maps.LatLng;
  location?: { lat: number, lng: number };
  imageUrl?: string;
  iconUrl?: string;
  name?: string;
}

@Component({
  selector: 'app-place-autocomplete',
  templateUrl: './place-autocomplete.component.html',
  styleUrls: ['./place-autocomplete.component.css']
})
export class PlaceAutocompleteComponent implements OnInit {

  @ViewChild('inputField') inputField: ElementRef;

  @Input() placeholder = 'Enter Address';

  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  autocomplete: google.maps.places.Autocomplete | undefined;

  constructor(private ngZone: NgZone) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputField.nativeElement);

    // Set component restrictions to India
    this.autocomplete.setComponentRestrictions({
      country: ['IN']
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete?.getPlace();

      // Log the place object for debugging
      console.log('Google Place:', place);

      const result: PlaceSearchResult = {
        address: this.inputField.nativeElement.value,
        name: place?.name,
        imageUrl: this.getPhotoUrl(place),
        location: place?.geometry?.location ? {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        } : undefined,
        iconUrl: place?.icon
      };

      // Log the result for debugging
      console.log('Result:', result);

      this.ngZone.run(() => {
        this.placeChanged.emit(result);
      });
    });
  }

  getPhotoUrl(place: google.maps.places.PlaceResult | undefined): string | undefined {
    return place?.photos && place.photos.length > 0 ? place.photos[0].getUrl({ maxWidth: 300 }) : undefined;
  }
}
