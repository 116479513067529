import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor() { }

  generateRandomPassword(): string {
    const alphabets = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    let alphabetsPart = '';
    let numbersPart = '';

    // Generate the alphabets part
    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * alphabets.length);
      alphabetsPart += alphabets[randomIndex];
    }

    // Generate the numbers part
    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * numbers.length);
      numbersPart += numbers[randomIndex];
    }

    // Combine both parts with '@' in between
    return `${alphabetsPart}@${numbersPart}`;
  }
}
