<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <div class="row">
    <div class="col">
      <p class="changePassword"><u>Change Password</u></p>
    </div>

    <div class="col" (click)="goBack()">
      <p class="pageReference">Quick serve > change password</p>
    </div>
  </div>

  <div class="row backgroundContainer">
    <div class="col"></div>
    <div class="col">
      <div class="card p-4">
        <p>Quick serve</p>
        <div class="div">
          <img
          class="profileImage"
          src="../../../assets/profileImage/profileImage.png"
          alt=""
        />
        </div>
      

        <form>
          <div class="mb-3">
            <label for="username" class="form-label"
              >Username</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="userName"
              [ngModelOptions]="{standalone: true}"
              id="username"
              disabled
              placeholder="Enter your username"
              maxlength="50"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label"
              >Current Password</label
            >
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              [(ngModel)]="curentPassword"
              [ngModelOptions]="{standalone: true}"
              placeholder="Enter your current password"
              maxlength="50"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword2" class="form-label"
              >New Password</label
            >
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword2"
              [(ngModel)]="newPassword"
              [ngModelOptions]="{standalone: true}"
              placeholder="Enter your new password"
              maxlength="50"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword3" class="form-label"
              >Confirm Password</label
            >
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword3"
              [(ngModel)]="confirmPassword"
              [ngModelOptions]="{standalone: true}"
              placeholder="Enter your new password"
              maxlength="50"
            />
          </div>
          <div *ngIf="spinnerForSubmitButton" style="float: right;" class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <button *ngIf="!spinnerForSubmitButton" type="submit" (click)="submit()" class="btn btn-success submitButton">Submit</button>
        </form>
      
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
