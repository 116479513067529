import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { Location } from '@angular/common';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from 'src/app/auth-service.service';
import { QsIDsService } from '../../services/qs-ids.service';

const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css'],
})
export class ProfilePageComponent implements OnInit {
  fromValue: any = {};

  MEUnitDetails: any;
  userName: string = '';
  meAppID: string = '';
  cdsRegID: string = '';
  GSTIN: string = '';
  phoneNumber: number = null;
  mailID: string = '';
  profileID: string = '';
  MEUnitName: string = '';
  address: string = '';
  district: string = '';
  edit: boolean = true;
  pinCode: number = null;
  approvalStatus: boolean = false;
  delete: boolean = false;
  addService: boolean = false;
  rupeesUnit: string = '';
  spinnerForProfilePage: boolean = false;

  services: string[];
  selectedService: string = '';
  serviceDescription: string = '';
  ratePerHour: number = null;
  serviceList: any;
  QRCode: any;

  imageUrls: (string | ArrayBuffer | null)[] = [null, null, null];
  compressedImages: File[] = [null, null, null];

  QRCodeImageUrls: (string | ArrayBuffer | null)[] = [];
  QRCodeCompressedImages: File[] = [];

  constructor(
    private location: Location,
    private http: HttpClient,
    private Authservice: AuthServiceService,
    private QsIDsService: QsIDsService
  ) {
    this.services = this.QsIDsService.getServiceLabels();
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  ngOnInit() {
    this.getMEUnitDescription();
  }

  checkExperience(input: HTMLInputElement) {
    if (input.value.length > 5) {
      input.value = input.value.slice(0, 5);
      this.ratePerHour = parseInt(input.value, 10);
      console.log('the rate per hour', this.ratePerHour);
    }
  }

  onServiceChange(event: any): void {
    const selectedServiceLabel = (event.target as HTMLSelectElement).value;
    const selectedServiceId =
      this.QsIDsService.getServiceIdByLabel(selectedServiceLabel);
    this.selectedService = selectedServiceId;
  }

  openAddServiceCard() {
    this.addService = !this.addService;
  }

  selectedServiceCancel() {
    this.addService = !this.addService;
    this.selectedService = '';
    this.serviceDescription = '';
    this.ratePerHour = null;
    this.imageUrls = [null, null, null];
  }

  checkLength(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber = parseInt(input.value, 10);
    }
  }

  checkPincodeLength(input: HTMLInputElement) {
    if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
      this.pinCode = parseInt(input.value, 10);
    }
  }

  goBack() {
    this.location.back();
  }

  enableEdit() {
    this.edit = false;

    console.log('the api ', this.fromValue);
    console.log(
      'the lat is',
      this.fromValue.location.lat,
      this.fromValue.location.lng
    );
    console.log('the address is', this.fromValue.address);
  }

  getMEUnitDescription() {
    this.spinnerForProfilePage = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.http.get(QS_ADMIN_URL + '/MEUnitProfilePage', { headers }).subscribe(
      (data: any) => {
        this.spinnerForProfilePage = false;
        console.log(data);
        this.MEUnitDetails = data;
        this.userName = this.MEUnitDetails.unitIncharge;
        this.meAppID = this.MEUnitDetails.meAppID;
        this.cdsRegID = this.MEUnitDetails.cdsRegID;
        this.GSTIN = this.MEUnitDetails.GSTIN;
        this.phoneNumber = this.MEUnitDetails.mobile;
        this.mailID = this.MEUnitDetails.mailID;
        this.profileID = this.MEUnitDetails.profileID;
        this.MEUnitName = this.MEUnitDetails.userName;
        this.address = this.MEUnitDetails.address.address;
        this.district = this.MEUnitDetails.address.district;
        this.pinCode = this.MEUnitDetails.address.pinCode;
        this.approvalStatus = this.MEUnitDetails.isApproved;
        this.delete = this.MEUnitDetails.accountDelete;
        this.serviceList = this.MEUnitDetails.services;
        this.QRCode = this.MEUnitDetails.QRcode;
        this.edit = this.MEUnitDetails.editPermissions;

        this.fromValue.address = this.address;

        if (!this.fromValue.location) {
          this.fromValue.location = { lat: null, lng: null };
        }

        if (this.MEUnitDetails.address.location.coordinates[1]) {
          this.fromValue.location.lat =
            this.MEUnitDetails.address.location.coordinates[1];
        }

        if (this.MEUnitDetails.address.location.coordinates[0]) {
          this.fromValue.location.lng =
            this.MEUnitDetails.address.location.coordinates[0];
        }

        console.log('the address field is updated', this.address);
        console.log('this.fromValue.address', this.fromValue.address);
        console.log('serviceList', this.serviceList);
        console.log('the lat is', this.fromValue.location.lat);
        console.log('the lat is', this.fromValue.location.lng);
      },
      (error) => {
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForProfilePage = false;
        }
      }
    );
  }

  updateServiceProviderDescription() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const updatedData = {
      userName: this.userName,
      meAppID: this.meAppID,
      cdsRegID: this.cdsRegID,
      GSTIN: this.GSTIN,
      phoneNumber: this.phoneNumber,
      mailID: this.mailID,
      profileID: this.profileID,
      MEUnitName: this.MEUnitName,
      address: this.fromValue.address,
      lat: this.fromValue.location.lat,
      lng: this.fromValue.location.lng,
      district: this.district,
      pinCode: this.pinCode,
    };

    console.log('the updated data is:', updatedData);

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to update it',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .put(QS_ADMIN_URL + '/update/MEUnitProfilePage', updatedData, {
            headers,
            params,
          })
          .subscribe(
            (response) => {
              console.log('Update successful', response);
              Swal.fire('Success!', 'Profile updated successfully.', 'success');
              this.getMEUnitDescription();
              this.edit = true;
            },
            (error) => {
              console.error('Error updating profile:', error);
              Swal.fire('Error!', 'Failed to update profile.', 'error');
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      }
    });
  }

  addServiceType() {
    if (
      this.selectedService === '' ||
      this.serviceDescription === '' ||
      this.ratePerHour === null ||
      this.rupeesUnit == ''
    ) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill all the required fields',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
      });
      return;
    }

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const formData = new FormData();

    formData.append('rupeesUnit', this.rupeesUnit);

    formData.append('serviceType', this.selectedService);
    formData.append('serviceDescription', this.serviceDescription);
    formData.append('ratePerHour', this.ratePerHour.toString());
    formData.append('profileID', this.profileID);

    for (let i = 0; i < this.compressedImages.length; i++) {
      if (this.compressedImages[i]) {
        console.log('the images are');
        formData.append(`images`, this.compressedImages[i]);
      }
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want add this service',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .put(QS_ADMIN_URL + '/addServices', formData, { headers, params })
          .subscribe(
            (response) => {
              console.log('Service added successfully', response);
              Swal.fire('Success!', 'Service added successfully.', 'success');
              this.getMEUnitDescription();
              this.edit = true;
              this.selectedService = '';
              this.serviceDescription = '';
              this.rupeesUnit =''
              this.ratePerHour = null;
              this.compressedImages = [null, null, null];
              this.imageUrls = [null, null, null];
              window.location.reload();
            },
            (error) => {
              console.error('Error adding service:', error);
              Swal.fire('Error!', 'Failed to add service.', 'error');
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      }
    });
  }

  async onFileChange(event: any, index: number) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      try {
        const compressedFile = await this.compressImage(file, index);
        this.compressedImages[index - 1] = compressedFile;
        const imageUrl = URL.createObjectURL(compressedFile);
        this.imageUrls[index - 1] = imageUrl;
      } catch (error) {
        console.error('Image compression error:', error);
      }
    }
  }

  compressImage(file: File, index: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 800;
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File(
                  [blob],
                  `${this.profileID}${this.selectedService}QSImage${index}.jpg`,
                  { type: 'image/jpeg' }
                );
                resolve(compressedFile);
              } else {
                reject(new Error('Image compression failed'));
              }
            },
            'image/jpeg',
            0.9
          );
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  async onQRCodeUpload(event: any, index: number) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      try {
        const compressedFile = await this.compressQRCodeImage(file, index);
        this.QRCodeCompressedImages[index - 1] = compressedFile;
        const imageUrl = URL.createObjectURL(compressedFile);
        this.QRCodeImageUrls[index - 1] = imageUrl;

        console.log('the QRCodeImageUrls', this.QRCodeImageUrls);
      } catch (error) {
        console.error('Image compression error:', error);
      }
    }
  }

  compressQRCodeImage(file: File, index: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 800;
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File(
                  [blob],
                  `${this.profileID}QRCodeImage${index}.jpg`,
                  { type: 'image/jpeg' }
                );
                resolve(compressedFile);
              } else {
                reject(new Error('Image compression failed'));
              }
            },
            'image/jpeg',
            0.9
          );
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  uploadQRCode() {
    if (this.QRCodeCompressedImages && this.QRCodeCompressedImages.length < 1) {
      Swal.fire({
        title: 'Please select the QRCode image to upload',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const formData = new FormData();
    formData.append('profileID', this.profileID);

    for (let i = 0; i < this.QRCodeCompressedImages.length; i++) {
      if (this.QRCodeCompressedImages[i]) {
        console.log('the QRCode are');
        formData.append(`QRCode`, this.QRCodeCompressedImages[i]);
      }
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want upload your QR Code',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .put(QS_ADMIN_URL + '/uploadQRcode', formData, { headers, params })
          .subscribe(
            (response) => {
              console.log('Service added successfully', response);
              Swal.fire('Success!', 'added successfully.', 'success');
              this.getMEUnitDescription();
              this.edit = true;
              this.selectedService = '';
              this.serviceDescription = '';
              this.ratePerHour = null;
              this.compressedImages = [null, null, null];
              this.imageUrls = [null, null, null];
              this.QRCodeCompressedImages = [];
              this.QRCodeImageUrls = [];
            },
            (error) => {
              console.error('Error adding service:', error);
              Swal.fire('Error!', 'Failed to add service.', 'error');
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      }
    });
  }
}
