<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>CMMU Units Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > CMMU Units</p>
    </div>
  </div>

  <br />

  <button *ngIf="spinnerForListingPage" class="btn btn-primary" type="button" disabled>
    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    Loading...
  </button>

  <div class="row" *ngIf="!spinnerForListingPage">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">ProfileID</th>
            <th scope="col">E-Mail</th>
            <th scope="col">District</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Account Status</th>
          </tr>
        </thead>
        <tbody *ngFor="let ulbListings of ulbListings; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ ulbListings.name }}</td>
            <td>{{ ulbListings.profileID }}</td>
            <td>{{ ulbListings.userAccount }}</td>
            <td>{{ ulbListings.district }}</td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUTCToIST(ulbListings.registeredDate) }}
            </td>
            <td>
              <span
                [class.active]="!ulbListings.accountDelete"
                [class.pending]="ulbListings.accountDelete"
              >
                {{ !ulbListings.accountDelete ? "Active" : "Deleted" }}
                </span
              >

        
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row" *ngIf="!spinnerForListingPage">
    <div class="col" *ngIf="this.isDistrictAdmin()">
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="getULBListings(currentPage - 1)" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index" [class.active1]="currentPage === i + 1">
            <a class="page-link" (click)="getULBListings(i + 1)">{{ i + 1 }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="getULBListings(currentPage + 1)" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>


    <div class="col" *ngIf="this.isSuperAdmin() || this.isStateAdmin()">
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="getULBListingsForStateAdmin(currentPage - 1)" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index" [class.active1]="currentPage === i + 1">
            <a class="page-link" (click)="getULBListingsForStateAdmin(i + 1)">{{ i + 1 }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="getULBListingsForStateAdmin(currentPage + 1)" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  

</div>
