import { Component } from '@angular/core';
import { QsIDsService } from '../../services/qs-ids.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from 'src/app/auth-service.service';

import {
  ElementRef,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  NgZone,
} from '@angular/core';

const QS_ADMIN_URL = environment.qsAdminUrl;
export interface PlaceSearchResult {
  address: string;
  // location?: google.maps.LatLng;
  location?: { lat: number; lng: number };
  imageUrl?: string;
  iconUrl?: string;
  name?: string;
}

@Component({
  selector: 'app-book-service-manually',
  templateUrl: './book-service-manually.component.html',
  styleUrls: ['./book-service-manually.component.css'],
})
export class BookServiceManuallyComponent implements OnInit {
  // google autocomplete place start
  @ViewChild('inputField') inputField: ElementRef;

  @Input() placeholder = 'Enter Address';

  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  autocomplete: google.maps.places.Autocomplete | undefined;

  // google autocomplete place end

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  minDate: Date;
  maxDate: Date;
  endMinDate: Date | null = null;
  endMaxDate: Date | null = null;
  googleAddress: any = null;
  selectedService: string = '';
  fullName: string = '';
  mobileNumber: number = null;
  workDescription: string = '';
  buidingName: string = '';
  streetName: string = '';
  landmark: string = '';
  pincode: number = null;
  selectedDistrict: string = '';
  ratePerHour: number = null;
  latitude: number;
  longitude: number;

  rupeesUnit: string = '';

  spinnerForSubmitButton: boolean = false;

  constructor(
    private http: HttpClient,
    private ngZone: NgZone,
    private Authservice: AuthServiceService,
    private QsIDsService: QsIDsService
  ) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    this.minDate = new Date(today);
    this.minDate.setDate(this.minDate.getDate() + 1);

    this.maxDate = new Date(this.minDate);
    this.maxDate.setDate(this.maxDate.getDate() + 90);
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  ngAfterViewInit(): void {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField.nativeElement
    );

    // Set component restrictions to India
    this.autocomplete.setComponentRestrictions({
      country: ['IN'],
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete?.getPlace();

      // Log the place object for debugging
      console.log('Google Place:', place);

      const result: PlaceSearchResult = {
        address: this.inputField.nativeElement.value,
        name: place?.name,
        imageUrl: this.getPhotoUrl(place),
        location: place?.geometry?.location
          ? {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }
          : undefined,
        iconUrl: place?.icon,
      };

      // Log the result for debugging
      console.log('Resultddd:', result.address);
      this.googleAddress = result.address;

      if (place?.geometry?.location) {
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
      }

      this.ngZone.run(() => {
        this.placeChanged.emit(result);
      });
    });
  }

  MEUnit: any = null;
  approvalStatus: any = null;
  services: any = null;
  registeredULB: any = null;

  ngOnInit() {
    this.getMEUnitDescription();
  }

  getMEUnitDescription() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // const params = new HttpParams().set('profileID', this.profileID);
    this.http.get(QS_ADMIN_URL + '/ApprovalStatus', { headers }).subscribe(
      (data) => {
        this.MEUnit = data;
        console.log(this.MEUnit);
        this.approvalStatus = this.MEUnit.isApproved;
        this.services = this.MEUnit.services;
        this.registeredULB = this.MEUnit.registeredULB;

        console.log('the approval status is: ' + this.approvalStatus);
        console.log('the services is: ' + this.services);

        // Handle success response if needed
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
        }
        // Handle error response
      }
    );
  }

  getPhotoUrl(
    place: google.maps.places.PlaceResult | undefined
  ): string | undefined {
    return place?.photos && place.photos.length > 0
      ? place.photos[0].getUrl({ maxWidth: 300 })
      : undefined;
  }

  onStartDateChange() {
    if (this.selectedStartDate) {
      this.endMinDate = new Date(this.selectedStartDate);
      this.endMinDate.setDate(this.endMinDate.getDate());

      this.endMaxDate = new Date(this.selectedStartDate);
      this.endMaxDate.setDate(this.endMaxDate.getDate() + 90);
    }
  }

  startDateFilter = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    return date >= this.minDate && date <= this.maxDate;
  };

  endDateFilter = (date: Date | null): boolean => {
    if (!date || !this.endMinDate || !this.endMaxDate) {
      return false;
    }
    return date >= this.endMinDate && date <= this.endMaxDate;
  };

  serviceLabels = [
    { id: 'qs_ID_01', value: 'Household cleaning' },
    { id: 'qs_ID_02', value: 'Cooking' },
    { id: 'qs_ID_03', value: 'Cooking and cleaning' },
    { id: 'qs_ID_04', value: 'Care taker' },
    { id: 'qs_ID_05', value: 'Vehicle washing' },
    { id: 'qs_ID_06', value: 'Deep cleaning units' },
    // { id: 'qs_ID_07', value: 'Vehicle cleaning' },
  ];

  onServiceChange() {
    console.log('Selected Service ID:', this.selectedService);
    return this.selectedService;
  }

  onDistrictChange() {
    console.log('Selected District:', this.selectedDistrict);
    return this.selectedDistrict;
  }

  // formSubmit() {
  //   console.log( this.fullName,this.selectedService,this.mobileNumber,this.workDescription,this.buidingName,this.streetName,this.landmark,this.pincode,this.selectedDistrict)
  //   if ((!this.fullName)||(!this.selectedService)||(!this.mobileNumber)||(!this.workDescription)||(!this.buidingName)||(!this.streetName)||(this.landmark)||(this.pincode)||(this.selectedDistrict)){
  //     Swal.fire('Please enter all fields');
  //   }
  //   else{
  //   console.log('Selected start date:', this.selectedStartDate);
  //   console.log('Selected end date:', this.selectedEndDate);
  //   console.log('Selected selected Service:', this.selectedService);
  //   console.log('Selected full Name:', this.fullName);
  //   console.log('Selected mobile Number:', this.mobileNumber);
  //   console.log('Selected work Description:', this.workDescription);
  //   console.log('Selected buiding Name:', this.buidingName);
  //   console.log('Selected street Name:', this.streetName);
  //   console.log('Selected landmark:', this.landmark);
  //   console.log('Selected pincode:', this.pincode);
  //   console.log('Selected selected District:', this.selectedDistrict);
  // }
  // }

  districts = [
    { value: 'Alappuzha', label: 'Alappuzha' },
    { value: 'Ernakulam', label: 'Ernakulam' },
    { value: 'Idukki', label: 'Idukki' },
    { value: 'Kannur', label: 'Kannur' },
    { value: 'Kasaragod ', label: 'Kasaragod' },
    { value: 'Kollam', label: 'Kollam' },
    { value: 'Kottayam', label: 'Kottayam' },
    { value: 'Kozhikode', label: 'Kozhikode' },
    { value: 'Malappuram', label: 'Malappuram' },
    { value: 'Palakkad', label: 'Palakkad' },
    { value: 'Pathanamthitta', label: 'Pathanamthitta' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Wayanad', label: 'Wayanad' },
  ];

  onSubmit(form: any) {
    if (this.approvalStatus == false) {
      Swal.fire('Error', 'Approval Status is pending', 'error');
      return false;
    }
  
    const errorFields = [];
  
    if (!this.selectedService) errorFields.push('Service');
    if (!this.selectedStartDate) errorFields.push('Start Date');
    if (!this.selectedEndDate && 
        this.selectedService !== 'qs_ID_02' && 
        this.selectedService !== 'qs_ID_07') errorFields.push('End Date');
    if (!this.fullName) errorFields.push('Full Name');
    if (!this.mobileNumber) errorFields.push('Mobile Number');
    else if (!/^\d{10}$/.test(this.mobileNumber.toString())) 
      errorFields.push('Mobile Number (must be 10 digits)');
    if (!this.workDescription) errorFields.push('Work Description');
    if (!this.ratePerHour) errorFields.push('Rate');
    if (!this.buidingName) errorFields.push('Building Name');
    if (!this.streetName) errorFields.push('Street Name');
    if (!this.landmark) errorFields.push('Landmark');
    if (!this.pincode) errorFields.push('Pincode');
    else if (!/^\d{6}$/.test(this.pincode.toString())) 
      errorFields.push('Pincode (must be exactly 6 digits)');
    if (!this.selectedDistrict) errorFields.push('District');
    if (!this.googleAddress) errorFields.push('Google Address');
    if (this.latitude === undefined || this.longitude === undefined) 
      errorFields.push('Please select the address from the dropdown');
    if (!this.rupeesUnit) errorFields.push('select the unit');



    
    if (errorFields.length > 0) {
      const errorMessage = `Please fill in or correct the following fields:\n${errorFields.join('\n')}`;
      Swal.fire({
        title: 'Form Validation Error',
        text: errorMessage,
        icon: 'error'
      });
      return false;
    }
  
    // Add confirmation SweetAlert
    Swal.fire({
      title: 'Confirm Submission',
      text: 'Are you sure you want to submit this service booking?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, submit it!',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitForm();
      }
    });
  
    return true;
  }
  
  private submitForm() {
    this.spinnerForSubmitButton = true;
    const enteredData = {
      address: this.googleAddress,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
      serviceType: this.selectedService,
      name: this.fullName,
      phoneNumber: this.mobileNumber,
      workDescription: this.workDescription,
      buildingName: this.buidingName,
      streetName: this.streetName,
      landMark: this.landmark,
      pinCode: this.pincode,
      district: this.selectedDistrict,
      ratePerHour: this.ratePerHour,
      rupeesUnit: this.rupeesUnit,
      latitude: this.latitude,
      longitude: this.longitude,
    };
  
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
    this.http
      .post<any>(`${QS_ADMIN_URL}/bookService`, enteredData, { headers })
      .subscribe(
        (response) => {
          console.log(response, 'received data');
          this.spinnerForSubmitButton = false;
          this.clear();
          Swal.fire('Success', 'Service booking submitted successfully', 'success');
        },
        (error) => {
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            Swal.fire('Error', 'Failed to submit service booking. Please try again.', 'error');

          }
          // console.error('Error submitting form:', error);
          // this.spinnerForSubmitButton = false;
          // Swal.fire('Error', 'Failed to submit service booking. Please try again.', 'error');
        }
      );
  }

  clear() {
    this.googleAddress = null;
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedService = '';
    this.fullName = null;
    this.mobileNumber = null;
    this.workDescription = null;
    this.buidingName = null;
    this.streetName = null;
    this.landmark = null;
    this.pincode = null;
    this.selectedDistrict = '';
    this.ratePerHour = null;
    this.rupeesUnit = '';

    if (this.inputField) {
      this.inputField.nativeElement.value = '';
    }
  
  }
}
