import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import Swal from 'sweetalert2';
import { QsIDsService } from '../../services/qs-ids.service';

import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

interface AnalyticsResponse {
  totalApproved: number;
  totalNotApproved: number;
  serviceProviderActive: number;
  serviceProviderInActive: number;
  serviceOrderCountsPending: number;
  serviceOrderCountsAccepted: number;
  serviceOrderCountsAssigned: number;
  serviceOrderCountsProgress: number;
  serviceOrderCountsCompleted: number;
  serviceOrderCountsCancelled: number;
  totalAmount: number;
}

@Component({
  selector: 'app-analytics-page',
  templateUrl: './analytics-page.component.html',
  styleUrls: ['./analytics-page.component.css'],
})
export class AnalyticsPageComponent implements OnInit {
  //variables
  startDate: any;
  endDate: any;
  approvedMEUnits: number = 0;
  notApprovedMEUnits: number = 0;
  totalServiceRequests: number = 0;
  // totalRevenue: number = 0;
  activeServiceProviders: number = 0;
  inActiveServiceProviders: number = 0;
  pendingServiceRequests: number = 0;
  progressServiceRequests: number = 0;
  assignedServiceRequests: number = 0;
  completedServiceRequests: number = 0;
  acceptedServiceRequests: number = 0;
  cancelledServiceRequests: number = 0;
  totalAmount: number = 0;

  spinnerForSearch: boolean = false;
  spinnerForExcelDownload: boolean = false;
  spinnerForPDFDownload: boolean = false;
  spinnerForServiceProvider: boolean = false;

  serviceProviderListing: any;

  districts = [
    { value: 'Alappuzha', label: 'Alappuzha' },
    { value: 'Ernakulam', label: 'Ernakulam' },
    { value: 'Idukki', label: 'Idukki' },
    { value: 'Kannur', label: 'Kannur' },
    { value: 'Kasaragod ', label: 'Kasaragod' },
    { value: 'Kollam', label: 'Kollam' },
    { value: 'Kottayam', label: 'Kottayam' },
    { value: 'Kozhikode', label: 'Kozhikode' },
    { value: 'Malappuram', label: 'Malappuram' },
    { value: 'Palakkad', label: 'Palakkad' },
    { value: 'Pathanamthitta', label: 'Pathanamthitta' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Wayanad', label: 'Wayanad' },
  ];

  districtLabel: string = '';
  districtValue: string = '';

  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private router: Router,
    private QsIDsService: QsIDsService
  ) {}

  ngOnInit() {}

  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, label: string) {
    console.log(`${label}:`, event.value);
  }

  search() {
    if (this.isStateAdmin() || this.isSuperAdmin()) {
      this.searchForStateAdmin();
      this.searchForStateAdminServiceProvider();
    } else if (this.isDistrictAdmin()) {
      this.searchForDistrictAdmin();
      this.searchForDistrictAdminServiceProvider();
    } else if (this.isULB()) {
      this.searchForULB();
      this.searchForCMMUServiceProvider();
    } else {
      this.searchForMEUnit();
      this.searchForMEUnitServiceProvider();
    }
  }

  searchForStateAdmin() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);
      this.http
        .get<AnalyticsResponse>(QS_ADMIN_URL + '/analytics', {
          headers,
          params,
        })
        .subscribe(
          (data: AnalyticsResponse) => {
            console.log(data);
            this.spinnerForSearch = false;
            this.approvedMEUnits = data.totalApproved;
            this.notApprovedMEUnits = data.totalNotApproved;
            this.activeServiceProviders = data.serviceProviderActive;
            this.inActiveServiceProviders = data.serviceProviderInActive;
            this.pendingServiceRequests = data.serviceOrderCountsPending;
            this.completedServiceRequests = data.serviceOrderCountsCompleted;
            this.acceptedServiceRequests = data.serviceOrderCountsAccepted;
            this.assignedServiceRequests = data.serviceOrderCountsAssigned;
            this.progressServiceRequests = data.serviceOrderCountsProgress;
            this.cancelledServiceRequests = data.serviceOrderCountsCancelled;
            this.totalServiceRequests =
              this.pendingServiceRequests +
              this.completedServiceRequests +
              this.acceptedServiceRequests +
              this.progressServiceRequests +
              this.assignedServiceRequests;
            this.totalAmount = data.totalAmount;
            console.log('approvedMEUnits:', this.approvedMEUnits);
            console.log('notApprovedMEUnits:', this.notApprovedMEUnits);
            console.log('activeServiceProviders:', this.activeServiceProviders);
            console.log(
              'inActiveServiceProviders:',
              this.inActiveServiceProviders
            );
            console.log(
              'cancelledServiceRequests:',
              this.cancelledServiceRequests
            );
            console.log(
              'acceptedServiceRequests:',
              this.acceptedServiceRequests
            );
            console.log(
              'completedServiceRequests:',
              this.completedServiceRequests
            );
            console.log('pendingServiceRequests:', this.pendingServiceRequests);
            console.log('total amount', this.totalAmount);
          },
          (error) => {
            console.error('Error', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }

  downloadAnalytics() {
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.downloadAnalyticsForStateAdmin();
    } else if (this.isDistrictAdmin()) {
      this.downloadAnalyticsForDistrictAdmin();
    } else if (this.isULB()) {
      this.downloadAnalyticsForCMMU();
    } else if (this.isMEUnit()) {
      this.downloadAnalyticsForMEUnit();
    }
  }

  downloadExcelAnalytics() {
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.downloadAnalyticsExcelForStateAdmin();
    } else if (this.isDistrictAdmin()) {
      this.downloadAnalyticsExcelForDistrictAdmin();
    } else if (this.isULB()) {
      this.downloadAnalyticsExcelForCMMU();
    } else if (this.isMEUnit()) {
      this.downloadAnalyticsExcelForMEUnit();
    }
  }

  downloadServiceProviderExcel() {
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.downloadServiceProviderAnalyticsExcelForStateAdmin();
    } else if (this.isDistrictAdmin()) {
      this.downloadServiceProviderAnalyticsExcelForDistrictAdmin();
    } else if (this.isULB()) {
      this.downloadServiceProviderAnalyticsExcelForCMMU();
    } else if (this.isMEUnit()) {
      this.downloadServiceProviderAnalyticsExcelForMEUnit();
    }
  }

  //---to download the pdf --------------------------------
  downloadAnalyticsForStateAdmin() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForPDFDownload = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/analytics/pdf`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute('download', 'analytics.pdf');
            this.spinnerForPDFDownload = false;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForPDFDownload = false;
            }
          }
        );
    }
  }
  //----------------------------------------------------------

  //--- to download as excel -----------------------------
  downloadAnalyticsExcelForStateAdmin() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForExcelDownload = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/analytics/excel`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute('download', 'analytics.xlsx');
            this.spinnerForExcelDownload = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForExcelDownload = false;
            }
          }
        );
    }
  }
  //-----------------------------------------------------------

  //---to download the pdf --------------------------------
  downloadAnalyticsForDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForPDFDownload = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/analyticsForDistrictAdmin/pdf`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute('download', 'analytics.pdf');
            this.spinnerForPDFDownload = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForPDFDownload = false;
            }
          }
        );
    }
  }
  //----------------------------------------------------------

  //--- to download as excel -----------------------------
  downloadAnalyticsExcelForDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForExcelDownload = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/analyticsForDistrictAdmin/excel`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute('download', 'analytics.xlsx');
            this.spinnerForExcelDownload = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForExcelDownload = false;
            }
          }
        );
    }
  }
  //-----------------------------------------------------------

  searchForDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate);
      this.http
        .get<AnalyticsResponse>(QS_ADMIN_URL + '/analyticsForDistrictAdmin', {
          headers,
          params,
        })
        .subscribe(
          (data: AnalyticsResponse) => {
            this.spinnerForSearch = false;
            this.approvedMEUnits = data.totalApproved;
            this.notApprovedMEUnits = data.totalNotApproved;
            this.activeServiceProviders = data.serviceProviderActive;
            this.inActiveServiceProviders = data.serviceProviderInActive;
            this.pendingServiceRequests = data.serviceOrderCountsPending;
            this.completedServiceRequests = data.serviceOrderCountsCompleted;
            this.acceptedServiceRequests = data.serviceOrderCountsAccepted;
            this.assignedServiceRequests = data.serviceOrderCountsAssigned;
            this.progressServiceRequests = data.serviceOrderCountsProgress;
            this.cancelledServiceRequests = data.serviceOrderCountsCancelled;
            this.totalServiceRequests =
              this.pendingServiceRequests +
              this.completedServiceRequests +
              this.acceptedServiceRequests +
              this.progressServiceRequests +
              this.assignedServiceRequests;
            this.totalAmount = data.totalAmount;
            console.log('approvedMEUnits:', this.approvedMEUnits);
            console.log('notApprovedMEUnits:', this.notApprovedMEUnits);
            console.log('activeServiceProviders:', this.activeServiceProviders);
            console.log(
              'inActiveServiceProviders:',
              this.inActiveServiceProviders
            );
            console.log(
              'cancelledServiceRequests:',
              this.cancelledServiceRequests
            );
            console.log(
              'acceptedServiceRequests:',
              this.acceptedServiceRequests
            );
            console.log(
              'completedServiceRequests:',
              this.completedServiceRequests
            );
            console.log('pendingServiceRequests:', this.pendingServiceRequests);
            console.log('total amount', this.totalAmount);
          },
          (error) => {
            console.error('Error', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }

  searchForULB() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate);
      this.http
        .get<AnalyticsResponse>(QS_ADMIN_URL + '/analyticsForULB', {
          headers,
          params,
        })
        .subscribe(
          (data: AnalyticsResponse) => {
            this.spinnerForSearch = false;
            this.approvedMEUnits = data.totalApproved;
            this.notApprovedMEUnits = data.totalNotApproved;
            this.activeServiceProviders = data.serviceProviderActive;
            this.inActiveServiceProviders = data.serviceProviderInActive;
            this.pendingServiceRequests = data.serviceOrderCountsPending;
            this.completedServiceRequests = data.serviceOrderCountsCompleted;
            this.acceptedServiceRequests = data.serviceOrderCountsAccepted;
            this.assignedServiceRequests = data.serviceOrderCountsAssigned;
            this.progressServiceRequests = data.serviceOrderCountsProgress;
            this.cancelledServiceRequests = data.serviceOrderCountsCancelled;
            this.totalServiceRequests =
              this.pendingServiceRequests +
              this.completedServiceRequests +
              this.acceptedServiceRequests +
              this.progressServiceRequests +
              this.assignedServiceRequests;
            this.totalAmount = data.totalAmount;
            console.log('approvedMEUnits:', this.approvedMEUnits);
            console.log('notApprovedMEUnits:', this.notApprovedMEUnits);
            console.log('activeServiceProviders:', this.activeServiceProviders);
            console.log(
              'inActiveServiceProviders:',
              this.inActiveServiceProviders
            );
            console.log(
              'cancelledServiceRequests:',
              this.cancelledServiceRequests
            );
            console.log(
              'acceptedServiceRequests:',
              this.acceptedServiceRequests
            );
            console.log(
              'completedServiceRequests:',
              this.completedServiceRequests
            );
            console.log('pendingServiceRequests:', this.pendingServiceRequests);
            console.log('total amount', this.totalAmount);
          },
          (error) => {
            console.error('Error', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }

  searchForMEUnit() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate);
      this.http
        .get<AnalyticsResponse>(QS_ADMIN_URL + '/analyticsForMEUnit', {
          headers,
          params,
        })
        .subscribe(
          (data: AnalyticsResponse) => {
            console.log(data);
            this.spinnerForSearch = false;
            this.approvedMEUnits = data.totalApproved;
            this.notApprovedMEUnits = data.totalNotApproved;
            this.activeServiceProviders = data.serviceProviderActive;
            this.inActiveServiceProviders = data.serviceProviderInActive;
            this.pendingServiceRequests = data.serviceOrderCountsPending;
            this.completedServiceRequests = data.serviceOrderCountsCompleted;
            this.acceptedServiceRequests = data.serviceOrderCountsAccepted;
            this.assignedServiceRequests = data.serviceOrderCountsAssigned;
            this.progressServiceRequests = data.serviceOrderCountsProgress;
            this.cancelledServiceRequests = data.serviceOrderCountsCancelled;
            this.totalServiceRequests =
              this.pendingServiceRequests +
              this.completedServiceRequests +
              this.acceptedServiceRequests +
              this.progressServiceRequests +
              this.assignedServiceRequests;
            this.totalAmount = data.totalAmount;
            console.log('approvedMEUnits:', this.approvedMEUnits);
            console.log('notApprovedMEUnits:', this.notApprovedMEUnits);
            console.log('activeServiceProviders:', this.activeServiceProviders);
            console.log(
              'inActiveServiceProviders:',
              this.inActiveServiceProviders
            );
            console.log(
              'cancelledServiceRequests:',
              this.cancelledServiceRequests
            );
            console.log(
              'acceptedServiceRequests:',
              this.acceptedServiceRequests
            );
            console.log(
              'completedServiceRequests:',
              this.completedServiceRequests
            );
            console.log('pendingServiceRequests:', this.pendingServiceRequests);
            console.log('total amount', this.totalAmount);
          },
          (error) => {
            console.error('Error', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }

  //---- service provider for MEunits --------------------------------
  searchForMEUnitServiceProvider() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate);
      this.http
        .get<AnalyticsResponse>(
          QS_ADMIN_URL + '/analyticsForMEUnitServiceProvider',
          {
            headers,
            params,
          }
        )
        .subscribe(
          (data: AnalyticsResponse) => {
            console.log(data);
            this.serviceProviderListing = data;
          },
          (error) => {
            console.error('Error', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
            }
            // Handle error response
          }
        );
    }
  }

  //-----service provider for CMMU --------------------------------
  searchForCMMUServiceProvider() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate);
      this.http
        .get<AnalyticsResponse>(
          QS_ADMIN_URL + '/analyticsForCMMUServiceProvider',
          {
            headers,
            params,
          }
        )
        .subscribe(
          (data: AnalyticsResponse) => {
            console.log(data);
            this.serviceProviderListing = data;
          },
          (error) => {
            console.error('Error', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
            }
            // Handle error response
          }
        );
    }
  }

  //-----service provider for CMMU --------------------------------
  searchForDistrictAdminServiceProvider() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate);
      this.http
        .get<AnalyticsResponse>(
          QS_ADMIN_URL + '/analyticsForDistrictAdminServiceProvider',
          {
            headers,
            params,
          }
        )
        .subscribe(
          (data: AnalyticsResponse) => {
            console.log(data);
            this.serviceProviderListing = data;
          },
          (error) => {
            console.error('Error', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
            }
            // Handle error response
          }
        );
    }
  }

  //-----service provider for StateAdmin --------------------------------
  searchForStateAdminServiceProvider() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);
      this.http
        .get<AnalyticsResponse>(
          QS_ADMIN_URL + '/analyticsForStateAdminServiceProvider',
          {
            headers,
            params,
          }
        )
        .subscribe(
          (data: AnalyticsResponse) => {
            console.log(data);
            this.serviceProviderListing = data;
          },
          (error) => {
            console.error('Error', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
            }
            // Handle error response
          }
        );
    }
  }

  //---to download the pdf --------------------------------
  downloadAnalyticsForCMMU() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForPDFDownload = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate);

      downloadUrl = `${QS_ADMIN_URL}/analyticsForULB/pdf`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute('download', 'analytics.pdf');
            this.spinnerForPDFDownload = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForPDFDownload = true;
            }
          }
        );
    }
  }
  //----------------------------------------------------------

  //--- to download as excel -----------------------------
  downloadAnalyticsExcelForCMMU() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForExcelDownload = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/analyticsForULB/excel`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute('download', 'analytics.xlsx');
            this.spinnerForExcelDownload = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForExcelDownload = false;
            }
          }
        );
    }
  }
  //-----------------------------------------------------------

  //---to download the pdf --------------------------------
  downloadAnalyticsForMEUnit() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForPDFDownload = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate);

      downloadUrl = `${QS_ADMIN_URL}/analyticsForMEUnit/pdf`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute('download', 'analytics.pdf');
            this.spinnerForPDFDownload = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForPDFDownload = false;
            }
          }
        );
    }
  }
  //----------------------------------------------------------

  //--- to download as excel -----------------------------
  downloadAnalyticsExcelForMEUnit() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForExcelDownload = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/analyticsForMEUnit/excel`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute('download', 'analytics.xlsx');
            this.spinnerForExcelDownload = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForExcelDownload = false;
            }
          }
        );
    }
  }
  //-----------------------------------------------------------

  //-------- analytics excel download for me unit -------------
  downloadServiceProviderAnalyticsExcelForMEUnit() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForServiceProvider = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/serviceProviderAnalyticsForMEUnit/excel`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute(
              'download',
              'service provider analytics.xlsx'
            );
            this.spinnerForServiceProvider = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForServiceProvider = false;
            }
          }
        );
    }
  }

  //-----------analytics of service provider for CMMU --------------------
  downloadServiceProviderAnalyticsExcelForCMMU() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForServiceProvider = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/serviceProviderAnalyticsForCMMU/excel`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute(
              'download',
              'service provider analytics.xlsx'
            );
            this.spinnerForServiceProvider = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForServiceProvider = false;
            }
          }
        );
    }
  }

  //-- download analytics for the service provider for district admin
  downloadServiceProviderAnalyticsExcelForDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForServiceProvider = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/serviceProviderAnalyticsForDistrictAdmin/excel`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute(
              'download',
              'service provider analytics.xlsx'
            );
            this.spinnerForServiceProvider = false;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForServiceProvider = false;
            }
          }
        );
    }
  }

  //--to download the excel for the service provider for stateadmin
  downloadServiceProviderAnalyticsExcelForStateAdmin() {
    if (!this.startDate) {
      Swal.fire('Please select start date');
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      let downloadUrl: string;
      this.spinnerForServiceProvider = true;
      const token = this.Authservice.getToken();

      console.log('The order function is called');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const params = new HttpParams()
        .set('startDate', this.startDate)
        .set('endDate', this.endDate)
        .set('districtValue', this.districtValue);

      downloadUrl = `${QS_ADMIN_URL}/serviceProviderAnalyticsForStateAdmin/excel`;

      this.http
        .get(downloadUrl, { headers, params, responseType: 'blob' as 'json' })
        .subscribe(
          (response: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response);
            downloadLink.setAttribute(
              'download',
              'service provider analytics.xlsx'
            );
            this.spinnerForServiceProvider = false;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            if (error.status === 401) {
              console.error('Unauthorized - logging out');
              this.Authservice.logOut();
            } else {
              console.error('Error fetching MEUnitProfilePage:', error);
              this.spinnerForServiceProvider = false;
            }
          }
        );
    }
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  goToServiceProviderDescription(profileID: string){
    // alert(profileID )
    this.router.navigate(['/serviceProviderDescription'], {
      queryParams: {
        profileID: profileID,
      },
    });


  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ('0' + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isULB(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isULB();
  }
  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }
}
