<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>ME Units Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > ME Units</p>
    </div>
  </div>

  <br>

  <button *ngIf="spinnerForMEUnitListings" class="btn btn-primary" type="button" disabled>
    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
    <span role="status">Loading...</span>
  </button>

  <div class="row" *ngIf="!spinnerForMEUnitListings">
    <div class="col">
      <table class="table table-bordered" >
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Approval Status</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody *ngFor="let MEUnitListings of MEUnitListings; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{MEUnitListings.userName}}</td>
            <td><i class="fa-solid fa-phone" *ngIf="MEUnitListings.mobile"></i> {{MEUnitListings.mobile}}</td>
        <td><i class="fa-solid fa-calendar-days"></i> {{ convertUTCToIST(MEUnitListings.registeredDate) }}</td>
            <td> <span [class.active]="MEUnitListings.isApproved"
              [class.pending]="!MEUnitListings.isApproved"> {{ MEUnitListings.isApproved ? "Active" : "Pending"}}</span></td>
            <td>
              <button type="button" (click)="goToMeUnitDescription(MEUnitListings.profileID)" class="btn btn-link">Details</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
