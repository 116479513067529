import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { Location } from '@angular/common';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from 'src/app/auth-service.service';
import { QsIDsService } from '../../services/qs-ids.service';

import { AbstractControl, ValidationErrors } from '@angular/forms';

const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css'],
})
export class ProfilePageComponent implements OnInit {
  fromValue: any = {};

  MEUnitDetails: any;
  userName: string = '';
  meAppID: string = '';
  cdsRegID: string = '';
  GSTIN: string = '';
  phoneNumber: number = null;
  mailID: string = '';
  profileID: string = '';
  MEUnitName: string = '';
  address: string = '';
  district: string = '';
  edit: boolean = true;
  pinCode: number = null;
  approvalStatus: boolean = false;
  delete: boolean = false;
  addService: boolean = false;
  rupeesUnit: string = '';
  spinnerForProfilePage: boolean = false;

  services: string[];
  selectedService: string = '';
  serviceDescription: string = '';
  ratePerHour: number = null;
  serviceList: any;
  QRCode: any;

  showLocalBodyDetails: boolean = false;

  imageUrls: (string | ArrayBuffer | null)[] = [null, null, null];
  compressedImages: File[] = [null, null, null];

  QRCodeImageUrls: (string | ArrayBuffer | null)[] = [];
  QRCodeCompressedImages: File[] = [];

  emailError: string = '';
  availableServices: string[] = [];

  constructor(
    private location: Location,
    private http: HttpClient,
    private Authservice: AuthServiceService,
    private QsIDsService: QsIDsService
  ) {
    this.services = this.QsIDsService.getServiceLabels();
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  ngOnInit() {
    this.fetchDistricts();
    this.getMEUnitDescription();
    this.fetchDistricts();
  }

  checkExperience(input: HTMLInputElement) {
    if (input.value.length > 5) {
      input.value = input.value.slice(0, 5);
      this.ratePerHour = parseInt(input.value, 10);
      console.log('the rate per hour', this.ratePerHour);
    }
  }

  validateEmail(control: AbstractControl): ValidationErrors | null {
    const email = control.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      return { required: 'Email is required' };
    }

    if (!emailRegex.test(email)) {
      return { invalidFormat: 'Invalid email format' };
    }

    return null;
  }

  onServiceChange(event: any): void {
    const selectedServiceLabel = (event.target as HTMLSelectElement).value;
    const selectedServiceId =
      this.QsIDsService.getServiceIdByLabel(selectedServiceLabel);
    this.selectedService = selectedServiceId;
  }

  openAddServiceCard() {
    this.addService = !this.addService;
  }

  selectedServiceCancel() {
    this.addService = !this.addService;
    this.selectedService = '';
    this.serviceDescription = '';
    this.ratePerHour = null;
    this.imageUrls = [null, null, null];
  }

  checkLength(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber = parseInt(input.value, 10);
    }
  }

  checkPincodeLength(input: HTMLInputElement) {
    if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
      this.pinCode = parseInt(input.value, 10);
    }
  }

  goBack() {
    this.location.back();
  }

  enableEdit() {
    this.edit = false;

    console.log('the api ', this.fromValue);
    console.log(
      'the lat is',
      this.fromValue.location.lat,
      this.fromValue.location.lng
    );
    console.log('the address is', this.fromValue.address);
  }

  getMEUnitDescription() {
    this.spinnerForProfilePage = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.http.get(QS_ADMIN_URL + '/MEUnitProfilePage', { headers }).subscribe(
      (data: any) => {
        this.spinnerForProfilePage = false;
        console.log(data);
        this.MEUnitDetails = data;
        this.userName = this.MEUnitDetails.unitIncharge;

        
      
        this.meAppID = this.MEUnitDetails.meAppID;
        this.cdsRegID = this.MEUnitDetails.cdsRegID;
        this.GSTIN = this.MEUnitDetails.GSTIN;
        this.phoneNumber = this.MEUnitDetails.mobile;
        this.mailID = this.MEUnitDetails.mailID;
        this.profileID = this.MEUnitDetails.profileID;
        this.MEUnitName = this.MEUnitDetails.userName;
        this.address = this.MEUnitDetails.address.address;
        this.district = this.MEUnitDetails.address.district;
        this.pinCode = this.MEUnitDetails.address.pinCode;
        this.approvalStatus = this.MEUnitDetails.isApproved;
        this.delete = this.MEUnitDetails.accountDelete;
        this.serviceList = this.MEUnitDetails.services;
        this.QRCode = this.MEUnitDetails.QRcode;
        this.edit = this.MEUnitDetails.editPermissions;

        this.showLocalBodyDetails = false;
        console.log('the showLocalBodyDetails is', this.showLocalBodyDetails);
        if(!this.MEUnitName){
          this.showLocalBodyDetails = true;
          console.log('the showLocalBodyDetails is', this.showLocalBodyDetails);
        }

        //localBody details 

        this.localBodyType = this.MEUnitDetails.localBodyType;
        this.localBodyName = this.MEUnitDetails.localBodyName;
        this.blockName = this.MEUnitDetails.blockName;

        console.log('the localBodyType is', this.localBodyType);
        console.log('the localBodyName is', this.localBodyName);
        console.log('the blockName is', this.blockName);
        

        this.serviceList = this.MEUnitDetails.services;
        this.updateAvailableServices();

        this.fromValue.address = this.address;

        if (!this.fromValue.location) {
          this.fromValue.location = { lat: null, lng: null };
        }

        if (this.MEUnitDetails.address.location.coordinates[1]) {
          this.fromValue.location.lat =
            this.MEUnitDetails.address.location.coordinates[1];
        }

        if (this.MEUnitDetails.address.location.coordinates[0]) {
          this.fromValue.location.lng =
            this.MEUnitDetails.address.location.coordinates[0];
        }

        console.log('the address field is updated', this.address);
        console.log('this.fromValue.address', this.fromValue.address);
        console.log('serviceList', this.serviceList);
        console.log('the lat is', this.fromValue.location.lat);
        console.log('the lat is', this.fromValue.location.lng);

        this.fetchDistricts();
      },
      (error) => {
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForProfilePage = false;
        }
      }
    );
  }

  updateAvailableServices() {
    const addedServices = new Set(
      this.serviceList.map((s) =>
        this.QsIDsService.getServiceLabel(s.serviceName)
      )
    );
    this.availableServices = this.services.filter(
      (service) => !addedServices.has(service)
    );
  }

  updateServiceProviderDescription() {
    // console.log('the selected level is', this.localBodyType);
    // console.log('the selected districtName is', this.districtName);
    // console.log('the selected district is', this.district);
    // console.log(
    //   'the selected municipality localBodyName is',
    //   this.localBodyName
    // );
    // console.log(
    //   'the selected selectedMunicipalCorp localBodyName is',
    //   this.localBodyName
    // );
    // console.log('the selected selectedBlock is', this.blockName);
    // console.log('the selected selectedGramPanchayat is', this.localBodyName);

    const requiredFields = [
      { name: 'ME Unit Name', value: this.MEUnitName },
      { name: 'Phone Number', value: this.phoneNumber },
      // { name: 'ME APP ID', value: this.meAppID },
      { name: 'CDS Registration ID', value: this.cdsRegID },
      { name: 'Name', value: this.userName },
      { name: 'E-mail ID', value: this.mailID },

      { name: 'Pin Code', value: this.pinCode },
      { name: 'District', value: this.district },
      { name: 'localBodyType', value: this.localBodyType },
      { name: 'localBodyName', value: this.localBodyName },
      // { name: 'blockName', value: this.blockName },
    ];

    if (this.localBodyType === 'Panchayat') {
      requiredFields.push({ name: 'Block Name', value: this.blockName });
    }

    const missingFields = requiredFields.filter((field) => !field.value);

    if (missingFields.length > 0) {
      const missingFieldNames = missingFields
        .map((field) => field.name)
        .join(', ');
      Swal.fire({
        title: 'Error',
        text: `Please fill in all required fields: ${missingFieldNames}`,
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    this.emailError = '';
    const emailValidation = this.validateEmail({
      value: this.mailID,
    } as AbstractControl);
    if (emailValidation) {
      this.emailError =
        emailValidation['required'] || emailValidation['invalidFormat'];
      return;
    }

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const updatedData = {
      userName: this.userName,
      meAppID: this.meAppID,
      cdsRegID: this.cdsRegID,
      GSTIN: this.GSTIN,
      phoneNumber: this.phoneNumber,
      mailID: this.mailID,
      profileID: this.profileID,
      MEUnitName: this.MEUnitName,
      address: this.fromValue.address,
      lat: this.fromValue.location.lat,
      lng: this.fromValue.location.lng,
      district: this.district,
      pinCode: this.pinCode,
      localBodyType: this.localBodyType,
      localBodyName: this.localBodyName,
      blockName: this.blockName,
    };

    console.log('the updated data is:', updatedData);

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to update it',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .put(QS_ADMIN_URL + '/update/MEUnitProfilePage', updatedData, {
            headers,
            params,
          })
          .subscribe(
            (response) => {
              console.log('Update successful', response);
              Swal.fire('Success!', 'Profile updated successfully.', 'success');
              this.getMEUnitDescription();
              this.edit = true;
            },
            (error) => {
              console.error('Error updating profile:', error);
              Swal.fire('Error!', 'Failed to update profile.', 'error');
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      }
    });
  }

  addServiceType() {
    if (
      this.selectedService === '' ||
      this.serviceDescription === '' ||
      this.ratePerHour === null ||
      this.rupeesUnit == ''
    ) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill all the required fields',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
      });
      return;
    }

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const formData = new FormData();

    formData.append('rupeesUnit', this.rupeesUnit);

    formData.append('serviceType', this.selectedService);
    formData.append('serviceDescription', this.serviceDescription);
    formData.append('ratePerHour', this.ratePerHour.toString());
    formData.append('profileID', this.profileID);

    for (let i = 0; i < this.compressedImages.length; i++) {
      if (this.compressedImages[i]) {
        console.log('the images are');
        formData.append(`images`, this.compressedImages[i]);
      }
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want add this service',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .put(QS_ADMIN_URL + '/addServices', formData, { headers, params })
          .subscribe(
            (response) => {
              console.log('Service added successfully', response);
              Swal.fire('Success!', 'Service added successfully.', 'success');
              this.getMEUnitDescription();
              this.edit = true;
              this.selectedService = '';
              this.serviceDescription = '';
              this.rupeesUnit = '';
              this.ratePerHour = null;
              this.compressedImages = [null, null, null];
              this.imageUrls = [null, null, null];
              this.updateAvailableServices();
              window.location.reload();
            },
            (error) => {
              console.error('Error adding service:', error);
              Swal.fire('Error!', 'Failed to add service.', 'error');
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      }
    });
  }

  // async onFileChange(event: any, index: number) {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const file = event.target.files[0];

  //     try {
  //       const compressedFile = await this.compressImage(file, index);
  //       this.compressedImages[index - 1] = compressedFile;
  //       const imageUrl = URL.createObjectURL(compressedFile);
  //       this.imageUrls[index - 1] = imageUrl;
  //     } catch (error) {
  //       console.error('Image compression error:', error);
  //     }
  //   }
  // }

  async onFileChange(event: any, index: number) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Check if the file is an image
      if (!file.type.startsWith('image/')) {
        Swal.fire({
          title: 'Error',
          text: 'Please upload only image files.',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
        // Clear the file input
        event.target.value = '';
        return;
      }

      try {
        const compressedFile = await this.compressImage(file, index);
        this.compressedImages[index - 1] = compressedFile;
        const imageUrl = URL.createObjectURL(compressedFile);
        this.imageUrls[index - 1] = imageUrl;
      } catch (error) {
        console.error('Image compression error:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to process the image. Please try again.',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
      }
    }
  }

  compressImage(file: File, index: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 800;
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File(
                  [blob],
                  `${this.profileID}${this.selectedService}QSImage${index}.jpg`,
                  { type: 'image/jpeg' }
                );
                resolve(compressedFile);
              } else {
                reject(new Error('Image compression failed'));
              }
            },
            'image/jpeg',
            0.9
          );
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  // async onQRCodeUpload(event: any, index: number) {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const file = event.target.files[0];

  //     try {
  //       const compressedFile = await this.compressQRCodeImage(file, index);
  //       this.QRCodeCompressedImages[index - 1] = compressedFile;
  //       const imageUrl = URL.createObjectURL(compressedFile);
  //       this.QRCodeImageUrls[index - 1] = imageUrl;

  //       console.log('the QRCodeImageUrls', this.QRCodeImageUrls);
  //     } catch (error) {
  //       console.error('Image compression error:', error);
  //     }
  //   }
  // }

  async onQRCodeUpload(event: any, index: number) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Check if the file is an image
      if (!file.type.startsWith('image/')) {
        Swal.fire({
          title: 'Error',
          text: 'Please upload only image files for QR Code.',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
        // Clear the file input
        event.target.value = '';
        return;
      }

      try {
        const compressedFile = await this.compressQRCodeImage(file, index);
        this.QRCodeCompressedImages[index - 1] = compressedFile;
        const imageUrl = URL.createObjectURL(compressedFile);
        this.QRCodeImageUrls[index - 1] = imageUrl;

        console.log('the QRCodeImageUrls', this.QRCodeImageUrls);
      } catch (error) {
        console.error('Image compression error:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to process the QR Code image. Please try again.',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
      }
    }
  }

  compressQRCodeImage(file: File, index: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 800;
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File(
                  [blob],
                  `${this.profileID}QRCodeImage${index}.jpg`,
                  { type: 'image/jpeg' }
                );
                resolve(compressedFile);
              } else {
                reject(new Error('Image compression failed'));
              }
            },
            'image/jpeg',
            0.9
          );
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  uploadQRCode() {
    if (this.QRCodeCompressedImages && this.QRCodeCompressedImages.length < 1) {
      Swal.fire({
        title: 'Please select the QRCode image to upload',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const formData = new FormData();
    formData.append('profileID', this.profileID);

    for (let i = 0; i < this.QRCodeCompressedImages.length; i++) {
      if (this.QRCodeCompressedImages[i]) {
        console.log('the QRCode are');
        formData.append(`QRCode`, this.QRCodeCompressedImages[i]);
      }
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want upload your QR Code',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .put(QS_ADMIN_URL + '/uploadQRcode', formData, { headers, params })
          .subscribe(
            (response) => {
              console.log('Service added successfully', response);
              Swal.fire('Success!', 'added successfully.', 'success');
              this.getMEUnitDescription();
              this.edit = true;
              this.selectedService = '';
              this.serviceDescription = '';
              this.ratePerHour = null;
              this.compressedImages = [null, null, null];
              this.imageUrls = [null, null, null];
              this.QRCodeCompressedImages = [];
              this.QRCodeImageUrls = [];
            },
            (error) => {
              console.error('Error adding service:', error);
              Swal.fire('Error!', 'Failed to add service.', 'error');
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      }
    });
  }

  //BLOCK, PANCHAYAT, CORPORATION selection starts----------------------------------------------------------------

  districtOFDistrictAdmin: any[] = [];
  districtName: string = '';
  blockName: string = '';
  localBodyName: string = '';
  localBodyType: string = 'Panchayat';

  fetchDistricts(): void {
    this.http
      .get<any>('assets/cloudKitchenPlaces/districtBlocks/districts.json')
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
        this.districtOFDistrictAdmin = [];
        for (let i = 0; i < this.districts.length; i++) {
          if (this.districts[i].name === this.district) {
            this.districtOFDistrictAdmin[0] = this.districts[i];
            console.log(
              'the districtOFDistrictAdmin is',
              this.districtOFDistrictAdmin
            );
            break; // Stop the loop once you find the matching district
          }
        }
      });
  }

  fetchDistrictsforMunicipalCorporation(): void {
    this.http
      .get<any>(
        'assets/cloudKitchenPlaces/municipalCorporations/districtsCorp.json'
      )
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
        this.districtOFDistrictAdmin = [];
        for (let i = 0; i < this.districts.length; i++) {
          if (this.districts[i].name === this.district) {
            this.districtOFDistrictAdmin[0] = this.districts[i];
            console.log(
              'the districtOFDistrictAdmin is',
              this.districtOFDistrictAdmin
            );

            break; // Stop the loop once you find the matching district
          }
        }
      });
  }

  fetchBlocksForDistrict(districtId: string): void {
    console.log('districtId: ', districtId);
    this.http
      .get<any>('assets/cloudKitchenPlaces/districtBlocks/blocks.json')
      .subscribe((data) => {
        this.blocks = data[districtId];
        console.log('the blocks are', this.blocks);
      });
  }
  tempBlocks: any;
  fetchGramPanchayatsForDistrict(districtId: string): void {
    console.log(districtId);
    this.http
      .get<any>(
        'assets/cloudKitchenPlaces/gramaPanchayats/' + districtId + '.json'
      )
      .subscribe((data) => {
        console.log(data);

        this.tempBlocks = data[`${this.selectedBlock}`];
        console.log(this.tempBlocks);
        this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
        this.gramPanchayats = this.tempBlocks;
        console.log('the gramPanchayats are', this.gramPanchayats);
      });
  }

  onDistrictChange(): void {
    this.selectedBlock = ''; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = '';
    this.selectedMunicipalityId = '';
    this.selectedMunicipalCorporationId = '';
    this.selectedMunicipality = null;
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    if (this.selectedDistrict) {
      this.districtName = this.selectedDistrict.name;
      console.log('District Name: ', this.districtName);
      if (this.selectedLevel === 'municipalityLevel') {
        this.selectedMunicipality =
          this.municipalities[this.selectedDistrict.id];
        console.log('municipality', this.selectedMunicipality);

        return;
      } else if (this.selectedLevel == 'municipalCorporation') {
        this.selectedMunicipalCorporation =
          this.municipalCorporations[this.selectedDistrict.id];
        console.log('municipalCorporation', this.selectedMunicipalCorporation);

        return;
      } else {
        console.log('this.selectedDistrict', this.selectedDistrict.id);

        this.fetchBlocksForDistrict(this.selectedDistrict.id);
        // Preload gram panchayats data for the selected district
        this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
      }
    }
  }

  selectedBlk: any = '';
  onBlockChange(): void {
    this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
    this.selectedBlock = this.selectedBlk.id;
    this.blockName = this.selectedBlk.name;
    console.log(this.selectedBlock);

    console.log('onBlockchnge', this.selectedDistrict.id);
    this.selectedGramPanchayat = ''; // Reset selected gram panchayat when block changes
    if (this.selectedBlock) {
      this.selectedGramPanchayat = ''; // Reset selected gram panchayat when block changes
      // this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
    }
    // Set the gram panchayats data based on the selected block
    if (this.selectedBlock && this.blocks[this.selectedBlock]) {
      this.gramPanchayats =
        this.gramPanchayats[this.selectedDistrict][this.selectedBlock];
      console.log('the gramPanchayats are', this.gramPanchayats);
    }
  }

  selectedGP: any = '';
  onPChange(): void {     
     this.localBodyType = 'Panchayat';
    this.selectedGramPanchayat = this.selectedGP.name;
    this.localBodyName = this.selectedGramPanchayat;
    // this.blockName=this.selectedBlk.name;
    console.log('Local Body Type: ', this.localBodyType);
    console.log('District: ', this.districtName);
    console.log('blockName: ', this.blockName);
    console.log('Local body name: ', this.localBodyName);
    // console.log(
    //   this.districtName,
    //   this.blockName,

    //   this.selectedGramPanchayat
    // );
  }

  // For Municipal Level

  municipalities: any;
  selectedMunicipality: any;
  selectedMunicipalityId: any;

  fetchMunicipalitiesForDistrict(): void {
    this.http
      .get<any>('assets/cloudKitchenPlaces/municipalities/municipalities.json')
      .subscribe((data) => {
        this.municipalities = data.municipalities;
        console.log(data.municipalities);
      });
  }

  selectedLevel: string = 'blockLevel'; // Property to store the selected level
  legalBody: string;
  districts: any[] = [];
  blocks: any;
  gramPanchayats: any = {};

  selectedDistrict: any = '';
  selectedBlock: string = '';
  selectedGramPanchayat: string = '';

  gramPanchayatsLoaded: boolean = false;

  // Method to handle radio button selection
  // For Municipal Level

  // Method to handle radio button selection
  onLevelSelection(selectedLevel: string): void {
    this.selectedDistrict = '';
    this.selectedBlock = ''; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = '';
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    this.selectedMunicipality = null;
    this.selectedMunicipalCorporation = null;
    this.selectedLevel = selectedLevel;
    this.selectedMunicipalCorp = '';

    console.log(this.selectedMunicipalityId);

    if (this.selectedLevel === 'blockLevel') {
      this.legalBody = 'PAN';
      this.localBodyType = 'Panchayat';
      this.fetchDistricts();
      // this.localBodyType = 'Panchayat';
    }
    if (this.selectedLevel === 'municipalityLevel') {
      this.legalBody = 'MUN';
      this.localBodyType = 'Municipality';
      this.fetchDistricts();
      this.fetchMunicipalitiesForDistrict();
    }
    if (this.selectedLevel === 'municipalCorporation') {
      this.legalBody = 'MNC';
      this.localBodyType = 'Corporation';

      this.fetchDistrictsforMunicipalCorporation();
      this.fetchMunicipalCorporationsForDistrict();
    }
    console.log('legalBody', this.legalBody);
  }
  selectedMunicipal: any = '';

  onMunicipalityChange(): void {
    // Implement actions to handle the change in selected municipality
    console.log('Selected municipality:', this.selectedMunicipal.name);
    this.selectedMunicipalityId = this.selectedMunicipal.id;
    this.localBodyName = this.selectedMunicipal.name;
    console.log('Local Body Type: ', this.localBodyType);
    console.log('District: ', this.districtName);
    console.log('Local body name: ', this.localBodyName);

    // You can perform additional actions here based on the selected municipality
  }

  selectedMunicipalCorp: any = '';
  onMunicipalCorporationChange(): void {
    this.selectedMunicipalCorporationId = this.selectedMunicipalCorp.id;
    this.localBodyName = this.selectedMunicipalCorp.name;
    console.log('Local Body Type: ', this.localBodyType);
    console.log('District: ', this.districtName);
    console.log('Local body name: ', this.localBodyName);
    // Implement actions to handle the change in selected municipality
    //  console.log('Selected municipality:', this.selectedMunicipalCorporationId);
    // You can perform additional actions here based on the selected municipality
  }

  //For Municipal Corporation Level;
  municipalCorporations: any = {};
  selectedMunicipalCorporation: any;
  selectedMunicipalCorporationId: any;

  fetchMunicipalCorporationsForDistrict(): void {
    // Assuming you are fetching data from a JSON file named municipal_corporations.json
    this.http
      .get<any>(
        'assets/cloudKitchenPlaces/municipalCorporations/municipal_corporation.json'
      )
      .subscribe((data) => {
        this.municipalCorporations = data.municipal_corporations || {};
        console.log(this.municipalCorporations);
      });
  }

  //BLOCK, PANCHAYAT, CORPORATION selection ends----------------------------------------------------------------

}
