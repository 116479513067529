import { Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private location: Location,
    private Authservice: AuthServiceService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.userName = localStorage.getItem('username') || '';
  }

  //variable declaration
  curentPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  userName: string = '';

  spinnerForSubmitButton: boolean = false;

  goBack() {
    this.location.back();
  }

  submit(): void {
    if (
      this.curentPassword === '' ||
      this.confirmPassword === '' ||
      this.userName == ''
    ) {
      return alert('All input fields are required');
    }
    if (this.newPassword !== this.confirmPassword) {
      return alert('Passwords does not match');
    }

    if (this.newPassword.length < 8) {
      return alert(
        'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
      );
    }

    const user = {
      curentPassword: this.curentPassword,
      newPassword: this.newPassword,
      userName: this.userName,
    };

    console.log(user);

    const token = this.Authservice.getToken();

    this.spinnerForSubmitButton = true;

    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('currentPassword', this.curentPassword)
      .set('newPassword', this.newPassword)
      .set('userName', this.userName);

    console.log('headers', headers);
    console.log('params', params);
    const options = { headers, params };
    this.http.put(QS_ADMIN_URL + '/changePassword', {}, options).subscribe(
      (data: any) => {
        Swal.fire(data.message);
        this.clearField();
        this.Authservice.logOut();
        this.spinnerForSubmitButton = false;
      },
      (error) => {
        console.log(error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
          this.spinnerForSubmitButton = false;
        } else {
          this.spinnerForSubmitButton = false;
          console.error('Error fetching MEUnitProfilePage:', error);
        }
      }
    );
  }

  clearField() {
    this.curentPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.userName == '';
  }
}
