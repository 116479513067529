<div class="container sideBar">
  <div class="row p-4">
    <div class="col">
      
      <p class="sideBarHeading"><img class="logoImage" src="../../../assets/logo/quickServe.jpg" alt=""/> Quick Serve</p>
    </div>
  </div>

  <div class="row pt-2 ps-4 pe-4">
    <div class="col ps-0 pe-0">
  <p style="margin-bottom: 0;color: #c2bfbf; font-size: small;">MENU</p>
    </div>
  </div>


  <div class="row pt-4 ps-4 pe-4" *ngIf="isSuperAdmin() || isStateAdmin() || isDistrictAdmin() || isULB() || isMEUnit()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToAnalyticsPage()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-chart-line" style="color: #ffffff"></i> Analytics
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isSuperAdmin() || isStateAdmin()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToCreateDistrictAdmin()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-solid fa-plus" style="color: #ffffff;"></i> Create District Admin
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isSuperAdmin() || isStateAdmin()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToULBListings()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-solid fa-rectangle-list" style="color: #ffffff;"></i> CMMU List
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isDistrictAdmin()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToCreateULB()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-solid fa-plus" style="color: #ffffff;"></i> Create CMMU
      </button>
    </div>
  </div>

  
 

  <div class="row pt-4 ps-4 pe-4" *ngIf="isULB()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToCreateMeUnit()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-regular fa-square-plus" style="color: #ffffff;"></i> Create ME unit
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isSuperAdmin() || isStateAdmin() || isDistrictAdmin() || isULB()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToMeUnitsListings()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-solid fa-list" style="color: #ffffff;"></i> ME Units List
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isMEUnit()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToServiceRequestListing()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-regular fa-rectangle-list" style="color: #ffffff;"></i> Service Request
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isMEUnit()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToAddServiceProvider()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-solid fa-user-plus" style="color: #ffffff;"></i> Add Service Provider
      </button>
    </div>
  </div>



  <div class="row pt-4 ps-4 pe-4" *ngIf="isMEUnit()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToServiceProviderListing()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-solid fa-rectangle-list" style="color: #ffffff;"></i> Service Provider Listing
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isMEUnit()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToBookServicesManually()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
      <i class="fa-solid fa-plus" style="color: #ffffff;"></i> Book service manually
      </button>
    </div>
  </div>
</div>
