<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!-------------------------------------------------------------------------->
  <div class="popup-overlay" *ngIf="showPopup" (click)="closePopup()"></div>
  <div class="popup" *ngIf="showPopup">
    <div class="mb-3">
      <label for="phoneNumber" class="form-label">Assign Service</label>
      <div class="list-container">
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let service of services"
            (click)="toggleServiceSelection(service.serviceName)"
            [class.active]="isSelected(service.serviceName)"
          >
            {{ getServiceLabel(service.serviceName) }}
          </li>
        </ul>
      </div>
    </div>

    <br />
    <button (click)="closePopup()" type="button" class="btn btn-secondary">
      Cancel
    </button>
    <button
      (click)="submitPopup()"
      style="float: right"
      type="button"
      class="btn btn-success"
    >
      Submit
    </button>
  </div>

  <!-------------------------------------------------------------------------->

  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Add Service Provider</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > Add service provider</p>
    </div>
  </div>

  <div class="card createCard m-4 p-4">
    <div class="row">
      <div class="col-6">
        <!--image upload for the service provider-->
        <div class="row mb-2">
          <div *ngIf="defaultImage" class="mt-3">
            <img
              [src]="defaultImage"
              alt="Image Preview"
              class="img-thumbnail"
              width="200px"
            />
          </div>
        </div>
        <!-------------------------------------->

        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="meUnitName" class="form-label">Name</label>
              <input
                type="text"
                [(ngModel)]="userName"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="meUnitName"
                maxlength="50"
                placeholder="Enter Name"
              />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label for="formFile" class="form-label">Upload Photo</label>

              <input
                class="form-control"
                type="file"
                id="formFile"
                (change)="onFileSelected($event, 'profileImage')"
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <!--personal details starts-->
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p style="margin-bottom: 0">
                  <b>Personal Details</b>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="ageInput" class="form-label">Age</label>
                  <input
                    type="text"
                    [(ngModel)]="age"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="ageInput"
                    maxlength="2"
                    placeholder="Enter Age"
                    (keypress)="validateAge($event)"
                    (input)="checkAgeLength()"
                  />
                </div>
                
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >Date of Birth</label
                  >
                  <input
                    type="date"
                    [(ngModel)]="dateOfBirth"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <!--radio button starts-->
                <label for="formFile3" class="form-label">Gender</label>
                <div class="row ps-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Male"
                      id="flexRadioDefault1"
                      [(ngModel)]="gender"
                      value="Male"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Male
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Female"
                      id="flexRadioDefault2"
                      [(ngModel)]="gender"
                      value="Female"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Female
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Others"
                      id="flexRadioDefault3"
                      [(ngModel)]="gender"
                      value="Others"
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      Others
                    </label>
                  </div>
                </div>

                <!--radio button ends-->
              </div>
              <div class="col">
                <!--radio button starts-->
                <label for="formFile3" class="form-label">Category</label>
                <div class="row ps-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="General"
                      id="flexRadioDefault1"
                      [(ngModel)]="category"
                      value="General"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      General
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="OBC"
                      id="flexRadioDefault2"
                      [(ngModel)]="category"
                      value="OBC"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      OBC
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="SC"
                      id="flexRadioDefault3"
                      [(ngModel)]="category"
                      value="SC"
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      SC
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="ST"
                      id="flexRadioDefault3"
                      [(ngModel)]="category"
                      value="ST"
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      ST
                    </label>
                  </div>
                </div>

                <!--radio button ends-->
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >Phone Number</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="phoneNumber1"
                    [ngModelOptions]="{ standalone: true }"
                    id="meUnitName"
                    placeholder="Enter Phone Number"
                    #phone1
                    (input)="checkphone1(phone1)"
                    (keypress)="validateAge($event)"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">WhatsApp</label>
                  <input
                    type="text"
                    [(ngModel)]="whatsAppNumber"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="10"
                    placeholder="Enter WhatsApp Number"
                    #whatsApp
                    (input)="checkWhatsApp(whatsApp)"
                    (keypress)="validateAge($event)"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="meUnitName" class="form-label"
                >Emergence Contact Number</label
              >
              <input
                type="text"
                [(ngModel)]="phoneNumber2"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="meUnitName"
                maxlength="10"
                placeholder="Emergence Contact Number"
                #phone2
                (input)="checkphone2(phone2)"
                (keypress)="validateAge($event)"
              />
            </div>

            <div class="mb-3">
              <label for="meUnitName" class="form-label"
                >Education Details</label
              >
              <input
                type="text"
                [(ngModel)]="educationDetails"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="meUnitName"
                maxlength="50"
                placeholder="Enter Education Details"
              />
            </div>

            <!-----------NHG details----------->
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">NHG Name</label>
                  <input
                    type="text"
                    [(ngModel)]="NHGName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="30"
                    placeholder="Enter NHG Name"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">NHG Number</label>
                  <input
                    type="text"
                    [(ngModel)]="NHGNumber"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="30"
                    placeholder="Enter NHG Number"
                  />
                </div>
              </div>
            </div>
            <!--------------------------------------->

            <!------auxiliary group details------>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >Auxiliary Group Name</label
                  >
                  <input
                    type="text"
                    [(ngModel)]="auxiliaryName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="50"
                    placeholder="Enter Auxiliary Group Name"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >Auxiliary Group Number</label
                  >
                  <input
                    type="text"
                    [(ngModel)]="auxiliaryNumber"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="50"
                    placeholder="Enter Auxiliary Group Number"
                  />
                </div>
              </div>
            </div>
            <!----------------------------->

            <div class="mb-3">
              <label for="formFileIdProof" class="form-label"
                >CDS Registration Documents</label
              >
              <input
                class="form-control"
                type="file"
                (change)="onFileSelected($event, 'cdsDocuments')"
                accept="application/pdf"
                id="formFileIdProof"
              />
            </div>

            <!--radio button starts-->
            <label for="formFile3" class="form-label"
              >Select the local bodies</label
            >
            <div class="row ps-4">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="localBodyType"
                  id="flexRadioDefault1"
                  [(ngModel)]="localBodyType"
                  value="Corporation"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Corporation
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="localBodyType"
                  id="flexRadioDefault2"
                  [(ngModel)]="localBodyType"
                  value="Municipality"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Municipality
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="localBodyType"
                  id="flexRadioDefault3"
                  [(ngModel)]="localBodyType"
                  value="Panchayat"
                />
                <label class="form-check-label" for="flexRadioDefault3">
                  Panchayat
                </label>
              </div>
            </div>

            <!--radio button ends-->
            <div class="mb-3" *ngIf="localBodyType === 'Corporation'">
              <label for="meUnitName" class="form-label"
                >Corporation Name</label
              >
              <input
                type="text"
                [(ngModel)]="localBodyName"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="meUnitName"
                placeholder="Enter Corporation Name"
                maxlength="50"
              />
            </div>

            <div class="mb-3" *ngIf="localBodyType === 'Municipality'">
              <label for="meUnitName" class="form-label"
                >Municipality Name</label
              >
              <input
                type="text"
                [(ngModel)]="localBodyName"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="meUnitName"
                placeholder="Enter Municipality Name"
                maxlength="50"
              />
            </div>

            <div class="mb-3" *ngIf="localBodyType === 'Panchayat'">
              <label for="meUnitName" class="form-label">Panchayat Name</label>
              <input
                type="text"
                [(ngModel)]="localBodyName"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="meUnitName"
                placeholder="Enter Panchayat Name"
                maxlength="50"
              />
            </div>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">Ward Name</label>
                  <input
                    type="text"
                    [(ngModel)]="wardName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Ward Name"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">Ward Number</label>
                  <input
                    type="text"
                    [(ngModel)]="wardNumber"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Ward Number"
                    #ward
                    maxlength="2"
                    (input)="checkAgeLength()"
                    (keypress)="validateAge($event)"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="meUnitName" class="form-label">Enter District</label>
              <input
                type="text"
                [(ngModel)]="districtName"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="meUnitName"
                placeholder="Enter District"
                disabled
                style="
                  background-color: rgb(206, 203, 203);
                  border-color: rgb(206, 203, 203);
                "
              />
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <!--personal details ends-->

        <br />
        <!--job details starts-->
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p style="margin-bottom: 0">
                  <b>Job Details</b>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col">
                <label for="formFile3" class="form-label">Job Details</label>
                <div class="row ps-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Full time"
                      id="flexRadioDefault1"
                      [(ngModel)]="jobType"
                      value="Full time"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Full time
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Part time"
                      id="flexRadioDefault2"
                      [(ngModel)]="jobType"
                      value="Part time"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Part time
                    </label>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">Experience</label>
                  <input
                    type="text"
                    [(ngModel)]="experience"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Experience"
                    #userExperience
                    maxlength="2"
                    (input)="checkExperience(userExperience)"
                    (keypress)="validateAge($event)"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="phoneNumber" class="form-label"
                    >Assign Service Provider</label
                  >
                  <p>
                    <button
                      (click)="openPopup()"
                      style="width: 100%"
                      type="button"
                      class="btn btn-secondary"
                    >
                      Assign Service
                    </button>

                  </p>
                 
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <!--job details ends-->

        <br />
      </div>

      <div class="col-6">
        <!--bank details starts-->
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p style="margin-bottom: 0">
                  <b>Bank Details</b>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <!------bank details starts ------------>
            <div class="container p-0">
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">Bank Name</label>
                    <input
                      type="text"
                      [(ngModel)]="bankName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="25"
                      placeholder="Enter Bank Name"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Bank Branch</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="bankBranch"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="30"
                      placeholder="Enter Branch Name"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Account Number</label
                    >
                    <input
                      type="text"
                      [(ngModel)]="bankAccountNumber"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="25"
                      placeholder="Enter Account Number"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">IFSC Code</label>
                    <input
                      type="text"
                      [(ngModel)]="bankIFSCcode"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="25"
                      placeholder="Enter IFSC Code"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="bankPassBook" class="form-label"
                      >Bank Pass Book</label
                    >
                    <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'bankPassBook')"
                      accept="application/pdf"
                      id="bankPassBook"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!------------------------------------>
          </mat-expansion-panel>
        </mat-accordion>
        <!--bank details ends-->

        <br />
        <!--bank documents starts-->
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p style="margin-bottom: 0">
                  <b>Personal Documents</b>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >ID Proof Name</label
                  >
                  <input
                    type="text"
                    [(ngModel)]="idProofName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter ID Proof Name"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >ID Proof Number</label
                  >
                  <input
                    type="text"
                    [(ngModel)]="idProofNumber"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter ID Proof Number"
                    maxlength="50"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="formFileIdProof" class="form-label"
                >ID Proof Document</label
              >
              <input
                class="form-control"
                type="file"
                (change)="onFileSelected($event, 'idProof')"
                accept="application/pdf"
                id="formFileIdProof"
              />
            </div>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >Address Proof Name</label
                  >
                  <input
                    type="text"
                    [(ngModel)]="addressProofName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Address Proof Name"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >Address Proof Number</label
                  >
                  <input
                    type="text"
                    [(ngModel)]="addressProofNumber"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Address Proof Number"
                    maxlength="50"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="formFileAddressProof" class="form-label"
                >Address Proof Document</label
              >
              <input
                class="form-control"
                type="file"
                (change)="onFileSelected($event, 'addressProof')"
                accept="application/pdf"
                id="formFileAddressProof"
              />
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <!--bank documents ends-->

        <br />
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="phoneNumber" class="form-label"
                >Generate Keyword</label
              >
              <button
                type="button"
                class="btn btn-primary"
                (click)="createPassword()"
                style="width: 100%"
              >
                Generate keyword button
              </button>
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label for="meUnitName" class="form-label">Keyword</label>
              <input
                type="text"
                [(ngModel)]="keyword"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                id="meUnitName"
                placeholder="Enter Keyword"
                maxlength="10"
              />
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div
            class="spinner-border text-success"
            style="float: right"
            role="status"
            *ngIf="spinner"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            *ngIf="!spinner"
            type="submit"
            (click)="addServiceProvider()"
            class="btn btn-success createButton"
          >
            Add service provider
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
