<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createMeUnit"><u>Create ME Unit</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > create ME Unit</p>
    </div>
  </div>

  <div class="row">
    <div class="col"></div>
    <div class="col-5 card createCard m-2 p-4">
      <form>
        <div class="mb-3">
          <label for="meUnitName" class="form-label">District</label>
          <input
            type="text"
            disabled
            [(ngModel)]="district"
            disabled
            style="
              background-color: rgb(206, 203, 203);
              border-color: rgb(206, 203, 203);
            "
            [ngModelOptions]="{ standalone: true }"
            class="form-control"
            id="meUnitName"
            maxlength="50"
            placeholder="District Name"
          />
        </div>
        <div class="mb-3">
          <label for="meUnitName" class="form-label">CMMU Name</label>
          <input
            type="text"
            disabled
            style="
              background-color: rgb(206, 203, 203);
              border-color: rgb(206, 203, 203);
            "
            [(ngModel)]="ULBName"
            disabled
            [ngModelOptions]="{ standalone: true }"
            class="form-control"
            id="meUnitName"
            maxlength="50"
            placeholder="Enter CMMU Name"
          />
        </div>

        <div class="mb-3">
          <label for="phoneNumber" class="form-label">Enter phone number</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="phoneNumber"
            [ngModelOptions]="{ standalone: true }"
            id="phoneNumber"
            placeholder="Enter phone number"
            #phoneInput
            (input)="checkLength(phoneInput)"
            (keypress)="validateAge($event)"
          />
        </div>

        <div class="mb-3">
          <label for="phoneNumber" class="form-label">Generate Keyword</label>
          <button
            type="button"
            class="btn btn-primary"
            (click)="createPassword()"
            style="width: 100%"
          >
            Generate keyword button
          </button>
        </div>

        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Keyword</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            [(ngModel)]="keyword"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Enter Keyword"
            maxlength="10"
          />
        </div>
        <div *ngIf="spinner" style="float: right;" class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button *ngIf="!spinner"
          (click)="createMEUnitAdmin()"
          type="submit"
          class="btn btn-success createButton"
        >
          Create ME Unit
        </button>
      </form>
    </div>
    <div class="col"></div>
  </div>
</div>
